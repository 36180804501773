var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header-actions"}},[_c('div',{staticClass:"header-actions"},[_c('s-button',{attrs:{"color":"primary","label":"Add New Category","icon":"IconIcRoundPlus","to":{ name: 'manage-category', params: { id: '+' } }}})],1)]),_c('sp-list',{ref:"list",attrs:{"params":_vm.$options.params,"perPage":_vm.$options.params.limit,"filters":_vm.filters,"attrs":_vm.columns,"endpoint":"categories","actions":['refresh', 'settings']},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var refresh = ref.refresh;
return [_c('div'),_c('div',{staticClass:"filter-section"},[_c('SearchFilter',{on:{"search":function($event){_vm.filters.search = $event}}}),_c('s-button-group',[_c('s-button',{staticClass:"refresh",attrs:{"icon":"vmdi-refresh","shape":"circle"},nativeOn:{"click":function($event){return refresh()}}})],1)],1)]}},{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.$router.push({ name: 'manage-category', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"attribute",fn:function(ref){
var item = ref.item;
return [(item.attribute.length)?_c('div',_vm._l((item.attribute[0].attributeOption),function(option,index){return _c('span',{key:index},[_c('StatusTag',{staticClass:"category-chip m-1",attrs:{"label":option.name}})],1)}),0):_c('span',[_vm._v("-")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex items-center gap-2"},[_c('div',{key:("state-modal-" + (item.id)),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleStatus(item)}}},[_c('s-switch',{key:("state-status-switch-" + (item.id)),attrs:{"id":("state-status-switch-" + (item.id)),"name":("state-status-switch-" + (item.id)),"value":item.id == _vm.selectedStatus.id ? _vm.selectedStatus.status : '' || item.status,"on-value":"active","off-value":"inactive","shape":"pill","color":"primary","size":"xs"}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }