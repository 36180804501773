<template>
  <div v-shilp-loader.overlay="isLoggingIn">
    <FilterBar @resetFilters="resetFilters">
      <template #default>
        <s-field label="Date">
          <CustomDatePicker :value="filters.date_range" @input="$emit('date-filter-selected', $event)" />
        </s-field>
        <s-field label="Company Status" class="mt-2">
          <v-select placeholder="Select" :options="$options.options.companyStatus"
            @input="$emit('status-filter-selected', $event)" :value="filters.status" />
        </s-field>

        <s-field label="Buyer Type" class="mt-2">
          <v-select :options="buyerTypeOptions" label="name" placeholder="Select" :reduce="(buyerType) => buyerType.id"
            :value="filters.buyer_type_id" @input="$emit('buyerTypeFilterSelected', $event)" />
        </s-field>

        <s-field label="Active Company" class="mt-2" desc="last 60 days" key="active_company_switch">
          <s-switch id="active_company_switch" :value="filters.active_company"
            @input="$emit('active-company-filter-selected', $event)" :on-value="60" :off-value="0" shape="pill"
            color="primary" size="xs" />
        </s-field>

        <s-field label="Company Created" class="mt-2" desc="By Admin" key="company_create_switch">
          <s-switch id="company_create_switch" :value="filters.company_create_admin"
            @input="$emit('company-create-admin', $event)" :on-value="true" :off-value="false" shape="pill"
            color="primary" size="xs" />
        </s-field>
      </template>
    </FilterBar>
    <sp-list ref="list" pagination-mode="infinite" v-infinite-scroll="loadMoreData" :params="params" :attrs="columns"
      :filters="filters" endpoint="companies" :actions="['search', 'filters']">
      <template #header="{ refresh }">
        <div></div>
        <div class="filter-section">
          <!-- <s-field class="filter-search">
            <s-textbox @input="$emit('search', $event)" placeholder="Search" :value="filters.search" />
          </s-field> -->
          <SearchFilter :value="filters.search" @search="$emit('search', $event)" />
          <s-button-group>
            <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
            <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
          </s-button-group>
        </div>
      </template>

      <template #empty>
        <ListEmpty title="No Companies Found" />
      </template>
      <sp-list-table @rowClick="rowClick">
        <template #name="{ item }">
          <span :class="item.read_status == 1 ? '' : 'font-bold'">{{ item.name }} </span>
        </template>

        <template #updated_at="{ item }">
          <span :class="item.read_status == 1 ? '' : 'font-bold'">{{ formatDate(item.updated_at) }}</span>
        </template>

        <template #kyc_status="{ item }">
          <KycStatusTag :label="item.kyc_status" />
        </template>

        <template #gst_no="{ item }">
          <span :class="item.read_status == 1 ? '' : 'font-bold'">{{ item.gst_no }}</span>
        </template>

        <template #status="{ item }">
          <Tag :label="item.status" :color="item.status == 'active' ? 'green' : 'red'" />
        </template>

        <template #assign="{ item }">
          <v-popover trigger="click" popoverWrapperClass="w-[300px]">
            <template #popover>
              <AssignTeamMember class="assign-member" :key="`assign-dropdown-${item.id}`" :company-id="item.id"
                @assigned="$refs.list.refresh()" :team-member="item.assignTo && item.assignTo.id" />
            </template>
            <s-button :key="`assign-btn-${item.id}`" title="Assign team member to company"
              icon="IconMdiAccountPlusOutline" color="grey" theme="muted" shape="circle" />
          </v-popover>
        </template>

        <template #device_type="{ item }">
          <s-icon v-if="item.users[0].device_type == 'web'" name="IconDesktopIcon" class="h-4 text-gray-400"
            v-tooltip.bottom="'Web User'" />
          <s-icon v-else-if="item.users[0].device_type == 'android'" name="IconAppIcon" class="h-4 text-gray-400"
            v-tooltip.bottom="'Application User'" />
        </template>

        <template #actions="{ item }">
          <s-button :key="`view-btn-${item.id}`" color="info" theme="muted" label="Profile"
            @click.native="viewCompanyProfile(item)" class="cursor-pointer" v-tooltip="'Go to company profile'" />
          <s-button label="Login" color="grey" theme="muted" @click.native="login(item)" class="ml-2"
            v-if="status == 'approve'" />
          <template v-if="isCompanyEditGranted">
            <s-button v-if="status == 'approve'" label="Edit" color="grey" theme="muted" @click.native="edit(item)"
              class="ml-2" />
          </template>
        </template>
      </sp-list-table>
    </sp-list>

    <!-- <AssignTeamModal /> -->
  </div>
</template>

<script>
import options from "@/data/options";
import { formatDate, getReadClassList } from "@/utilities/common";
import { mapMutations, mapGetters } from "vuex";
import tableMixin from "@/mixins/table";
import { loginAsBuyer } from "@/api/loginAsBuyer";
import roleMixin from "@/mixins/role";
import kycStatus from "@/data/kycStatus";
import bus from "@/bus";
export default {
  options,
  components: {
    KycStatusTag: require("@/components/KycStatusTag.vue").default,
    // AssignTeamModal: require("./AssignTeamModal.vue").default,
    ListEmpty: require("@/components/ListEmpty.vue").default,
    AssignTeamMember: require("@/components/company/AssignTeamMember.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    CustomDatePicker: require("@/components/CustomDatePicker.vue").default,
    FilterBar: require("@/components/FilterBar").default,
  },
  props: ["status", "filters"],
  mixins: [tableMixin, roleMixin],
  data() {
    return {
      kycStatus,
      isLoggingIn: false,
      // filters: {
      //   kyc_status: this.$route.params.status,
      //   status: null,
      //   days: null,
      // },
    };
  },


  created() {
    bus.$on("kyc-status-changed", () => {
      this.$refs.list.refresh();
    });
    bus.$on("new-registration", () => {
      this.$refs.list.refresh();
    });
  },

  computed: {
    ...mapGetters({
      buyerTypeOptions: "buyerTypeOptions",
    }),

    columns() {
      return [
        {
          name: "name",
          width: "300px",

          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "updated_at",
          label: "Updated At",
          sortable: true,
          width: "300px",

          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "gst_no",
          width: "180px",
          label: "GST No.",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "last_order_date",
          label: "Last Order Date",
          width: "300px",
          value: (item) => formatDate(item.last_order_date),
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "kyc_status",
          label: "KYC Status",
          width: "215px",
          rowClick: false,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "team",
          label: "Team Member",
          classList(row) {
            return getReadClassList(row.read_status);
          },
          width: "200px",
          value: (item) => (item.assignTo && item.assignTo.name ? `${item.assignTo.name} (${item.assignTo.role[0]})` : ""),
        },
        {
          name: "assign",
          label: "Assign to",
          rowClick: false,
          width: "180px",
          visible: this.isTeamMemberListVisible,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "device_type",
          label: "Device Type",
          width: "180px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "actions",
          label: "Actions",
          rowClick: false,
          fix: true,
          width: "250px",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
      ];
    },

    params() {
      return {
        limit: 25,

        // days: this.days,
        include: "assign_to, users",
        sort: {
          by: "updated_at",
          order: this.status == "inreview" ? "asc" : "desc",
        },
      };
    },
  },
  methods: {
    // ...mapMutations(["setCompanyFilterId", "setStoreAppInstance"]),
    ...mapMutations({
      setCompanyFilterId: "setCompanyFilterId",
      // setStoreAppInstance: "storeApp/setStoreAppInstance",
    }),

    edit(company) {
      this.$router.push({ name: "upsert-company", params: { id: company.id } });
    },

    formatDate,
    getReadClassList,
    rowClick($event) {
      this.setCompanyFilterId($event.id);
      this.$router.push({ name: this.status == "approve" ? "approved-companies-statistics" : "company-statistics", params: { id: $event.id }, query: { status: this.status } });
    },
    viewCompanyProfile($event) {
      this.setCompanyFilterId($event.id);
      this.$router.push({ name: this.status == "approve" ? "approved-company-profile" : "company-profile", params: { id: $event.id }, query: { status: this.status } });
    },

    // resetFilters() {
    //   this.filters = {
    //     kyc_status: this.$route.params.status,
    //     status: null,
    //     days: null,
    //   };
    // },

    resetFilters() {
      this.$emit("resetFilters");
    },

    login(row) {
      this.isLoggingIn = true;
      loginAsBuyer(row.id)
        .then((res) => {
          window.open(`${process.env.VUE_APP_STORE_BASE_URL}/#/login?token=${res.token}&by_admin_id=${res.by_admin_id}&company_id=${row.id}`, "_blank");
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoggingIn = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.sp-table {
  border-radius: 0;
}

.button-group {
  @apply relative block;
}

.filter-section {
  @apply flex gap-4 items-center;
}

.has-applied {
  @apply text-secondary-500 z-10;
  background: none !important;
}

.not-applied {
  @apply text-gray-500;
}

.animate {
  @apply absolute left-0 bg-secondary-100 animate-ping p-4 rounded-full;
}

.assign-member {
  ::v-deep .v-select {
    ul {
      position: relative !important;
    }
  }
}
</style>
