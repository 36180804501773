<template>
  <div>
    <div v-if="module == 'master'">
      <s-button-group color="secondary" theme="tab" size="lg">
        <s-button>
          <template #before>
            <span :class="{ 'text-secondary-500 font-bold': isActive(null) }" @click="onSelect(null)">
              ALL <span v-if="counts">({{ counts.total }})</span></span
            >
          </template>
          <template #after>
            <span class="ml-2" :class="{ 'text-secondary-500 font-bold': isActive('unread-filter') }" @click="onSelect('unread-filter')"> Unread ({{ unreadCount }})</span>
          </template>
        </s-button>

        <s-button v-for="inquiry in $options.inquiryType" :key="inquiry.value" :label="inquiry.label" class="mb-2" :active="isActive(inquiry.value)" @click.native="onSelect(inquiry.value)">
          <template #after>
            <span class="ml-2 font-bold" v-if="counts"> ({{ counts[inquiry.name] }}) </span>
          </template>
        </s-button>
      </s-button-group>
    </div>
    <div v-else>
      <s-button-group color="secondary" theme="tab" size="lg">
        <s-button>
          <template #before>
            <span :class="{ 'text-secondary-500 font-bold': isActive(null) }" @click="onSelect(null)">
              ALL <span v-if="typeCounts">({{ typeCounts.total }})</span></span
            >
          </template>
          <template #after>
            <span class="ml-2" :class="{ 'text-secondary-500 font-bold': isActive('unread-filter') }" @click="onSelect('unread-filter')"> Unread ({{ unreadCount }})</span>
          </template>
        </s-button>

        <s-button v-for="issue in issueTypes" :key="issue.id" :label="issue.display_name" class="mb-2" :active="isActive(issue.id)" @click.native="onSelect(issue.id)">
          <template #after>
            <span class="ml-2 font-bold" v-if="typeCounts && issue.name != 'Account Modification'"> ({{ typeCounts[issue.name.toLowerCase().replaceAll('-', "_")] }}) </span>
            <span class="ml-2 font-bold" v-if="typeCounts && issue.name == 'Account Modification'"> ({{ typeCounts["account_modification"] }}) </span>
          </template>
        </s-button>
      </s-button-group>
    </div>
  </div>
</template>

<script>
import { categoryWiseInquiryCount } from "@/api/inquiry";
import { getTypes, getTypeCounts } from "@/api/vendor/issue";
import { inquiryType } from "@/data/index";
import { mapGetters } from "vuex";

export default {
  // props: ["companyId"],
  props: {
    filters: Object,
    module: String,
  },
  inquiryType,
  data() {
    return {
      active: null,
      counts: null,
      isCountLoading: true,
      issueTypes: [],
      typeCounts: null,
    };
  },
  created() {
    if (this.module == "vendor") {
      this.getIssueTypes();
      this.getIssueCount();
    } else {
      this.getCount();
    }
  },
  computed: {
    ...mapGetters(["companyFilterId"]),

    unreadCount() {
      if (this.module == "vendor") {
        if (this.typeCounts && this.typeCounts.total) return this.typeCounts?.total - this.typeCounts?.read_count;
        else return 0;
      } else {
        if (this.counts && this.counts.total) return this.counts?.total - this.counts?.read_count;
        else return 0;
      }
    },
  },
  watch: {
    companyFilterId() {
      if (this.module == "vendor") {
        this.getIssueCount();
      } else {
        this.getCount();
      }
    },
    filters: {
      immediate: true,
      handler() {
        if (this.module == "vendor") {
          this.getIssueCount();
        } else {
          this.getCount();
        }
      },
    },
  },
  methods: {
    getCount() {
      this.isCountLoading = true;
      categoryWiseInquiryCount({
        filter: {
          company_id: this.companyFilterId,
          ...this.filters,
          // Setting read status to undefined since it is affecting the count of other types
          read_status: undefined,
          // Below key is set to null ,because api returns 0 in various types when id is sent which is not expected.
          inquiry_type: null,
        },
      }).then((res) => {
        this.counts = res;
        this.isCountLoading = false;
      });
    },
    getIssueCount() {
      this.isCountLoading = true;
      const params = {
        filter: {
          ...this.filters,
          vendor_issue_type_id: null,
        },
      };
      getTypeCounts(params).then((res) => {
        this.typeCounts = res;
        this.isCountLoading = false;
      });
    },
    getIssueTypes() {
      getTypes().then((res) => {
        this.issueTypes = res.data;
      });
    },
    isActive(id) {
      if (id == this.active) return true;
      else return false;
    },
    onSelect(id) {
      if (id == "unread-filter") {
        // this.getCount();
        if (this.module == "vendor") {
          this.getIssueCount();
        } else {
          this.getCount();
        }
        this.active = "unread-filter";
        this.$emit("unread-filter-selected");
      } else {
        // this.getCount();
        if (this.module == "vendor") {
          this.getIssueCount();
        } else {
          this.getCount();
        }
        this.active = id;
        this.$emit("selected", this.active);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
