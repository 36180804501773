<template>
  <div>
    <div v-if="!isApiCalling && details" class="w-full">
      <div class="flex flex--fit flex--nowrap">
        <div class="w-1/2">
          <div>
            <div class="bg-white rounded p-3 mb-2">
              <div class="flex flex--fit">
                <div class="w-1/2">
                  <label>Company Name</label>
                  <p class="value">{{ details.user ? details.user.name : "-" }}</p>
                </div>
                <div class="w-1/2">
                  <label>Company Number</label>
                  <p class="value">{{ details.user ? details.user.mobile_no : "-" }}</p>
                </div>
              </div>
              <div class="mt-2 flex flex--fit">
                <div class="w-1/2">
                  <label>Project Name</label>
                  <p class="value">{{ details.vendorOrder ? details.vendorOrder.project_name : "-" }}</p>
                </div>
                <div class="w-1/2">
                  <label>Issue ID</label>
                  <p class="value">{{ details.issue_no || "-" }}</p>
                </div>
              </div>
            </div>
            <div class="bg-white rounded p-3 mb-2">
              <div class="flex flex--fit justify-between">
                <div>
                  <label> Date & Time </label>
                  <p class="value" v-if="details && details.created_at">{{ formatDate(details.created_at) }}</p>
                </div>

                <div>
                  <div class="flex flex--fit">
                    <label class="mr-7"> Status </label>
                    <v-popover trigger="click">
                      <template #popover>
                        <div class="select-status">
                          <v-select :options="inquiryStatusOptions" placeholder="Select" :reduce="(item) => item.id"
                          class="w-60 " v-model="selectedStatus" :clearable="false">
                          <template v-slot:option="inquiryStatusOptions">
                            <StatusTag :label="inquiryStatusOptions.label"
                              :name="inquiryStatusOptions.name == 'Under Process' ? 'under_process' : inquiryStatusOptions.name.toLowerCase()" />
                          </template></v-select>
                        </div>
                        
                        <s-button color="primary" label="Submit" class="mt-2" @click.native="resolved"
                          :loader="savingStatus" />
                      </template>
                      <!-- <s-button title="Change Inquiry Status" key="product-inquiry-status-modal-btn" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" /> -->
                      <a class="text-blue-500 font-medium cursor-pointer">Edit</a>
                    </v-popover>
                  </div>
                  <div class="flex flex--fit" v-if="inquiryStatusOptions && details">
                    <StatusTag class="w-24 text-center mr-1" :label="details.issueStatus.display_name"
                      :name="details.issueStatus.name == 'Under Process' ? 'under_process' : details.issueStatus.name.toLowerCase()" />
                  </div>
                </div>

                <div>
                  <label> Type </label>
                  <div class="mt-2">
                    <StatusTag v-if="details && details.vendor_issue_type"
                      :label="details.vendor_issue_type.display_name"
                      :name="details.vendor_issue_type.name == 'Account Modification' ? 'account_modification' : details.vendor_issue_type.name.toLowerCase()" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white rounded p-3 mb-2" v-if="details.vendor_issue_type.name == 'request-for-brand'">
              <div class="grid grid--2 gap-4">
                <div class="mt-2 col">
                  <label>Requested Brands</label>
                  <p class="value">{{ details.brand_names }}</p>
                </div>

                <div class="mt-2">
                  <label>Locations</label>
                  <v-popover trigger="hover" popoverWrapperClass="w-[300px]">
              <template #popover>
                <div v-for="(state, index) in details.locations" :key="state.id">
                  <p class="word-wrap">{{ index + 1 }} - {{ state.name }}: {{ getCityNames(state.cities) }}</p>
                  <br />
                </div>
              </template>
              <StatusTag class="location-chip mr-1" :label="`States: ${details.location_counts.state}`" />
              <StatusTag class="location-chip" :label="`Cities: ${details.location_counts.city}`" />
            </v-popover>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white rounded p-3 flex flex--nowrap justify-between" v-if="details.vendorOrder">
            <div>
              <label>Brand</label>
              <p class="value">{{ details.vendorOrder.brand.name || "-" }}</p>

              <div class="mt-2">
                <label>Size Packing</label>
                <p class="value">{{ details.vendorOrder.packing || details.vendorOrder.size || "-" }}</p>
              </div>
            </div>

            <div>
              <label>Category</label>
              <p class="value">{{ details.vendorOrder.category.name || "-" }}</p>

              <div class="mt-2">
                <label>Quantity</label>
                <p class="value">{{ details.vendorOrder.qty || "-" }}</p>
              </div>
            </div>

            <div>
              <label>Grade</label>
              <p class="value">{{ details.vendorOrder.grade_name || "-" }}</p>
            </div>

            <div>
              <label>OrderID</label>
              <p>
                <a class="text-blue-500 font-medium cursor-pointer text-base"
                  @click="$router.push({ name: 'vendor-order-details', params: { id: details.vendorOrder.id, order_no: details.vendor_order_no } })">{{
                    details.vendor_order_no || "-" }}</a>
              </p>
            </div>
          </div>

          <div class="flex flex--nowrap w-full bg-white rounded mt-2 p-3" v-if="details.description || details.issue_media">
            <div class="w-1/2">
              <s-field label="Description" class="my-2" v-if="details && details.description">
                <s-textarea :value="details.description" :disabled="true" :rows="4" />
              </s-field>

              <s-field label="Attachments" class="my-2 ml-1" v-if="details && details.issue_media">
                <a :href="details.issue_media.url" target="_blank"><img :src="details.issue_media.url"
                    class="h-40" /></a>
              </s-field>
            </div>
          </div>
        </div>

        <div class="bg-white rounded ml-2 w-1/2 p-3">
          <div class="flex flex--fit justify-between">
            <p class="text-md font-medium text-gray-800">Comments</p>
            <s-button label="+ Add comment" color="primary" class="button--sm"
              @click.native="$root.$emit('shilp-modal-open', 'comment-modal')">Comments</s-button>
          </div>
          <div v-for="comments in details.issueComments" :key="comments.id">
            <div class="flex flex--fit">
              <s-icon name="IconCustomerSupport" class="mt-3" />
              <span class="text-gray-400">
                <span class="text-sm font-bold text-gray-800 ml-1">{{ comments.adminUser.name }}</span>
                <span class="font-bold text-3xl mx-1">.</span>
                <span class="font-medium text-xs">{{ formatDate(comments.created_at) }}</span>
              </span>
            </div>
            <p class="text-sm font-medium">{{ comments.description }}</p>
            <hr class="mt-1" />
          </div>
          <CommentModal :allComments="details.issueComments" @refreshComments="refreshComments" />
        </div>
      </div>
    </div>
    <Loader class="flex" v-else />
  </div>
</template>


<script>
import { formatDate } from "@/utilities/common";
import { mapGetters } from "vuex";
import StatusTag from "@/components/StatusTag.vue";
import { getDetails, update } from "@/api/vendor/issue.js";
import Loader from "@/components/Loader.vue";
import CommentModal from "./CommentModal.vue";
export default {
  data() {
    return {
      details: null,
      selectedStatus: null,
      isApiCalling: false,
      savingStatus: false,
    };
  },
  computed: {
    ...mapGetters(["inquiryStatusOptions"]),
  },
  mounted() {
    this.getIssueById(this.$route.params.id);
  },
  components: {
    StatusTag,
    Loader,
    CommentModal,
  },
  methods: {
    formatDate,
    getIssueById(id) {
      this.isApiCalling = true;
      const params = {
        include: "issueStatus,vendorIssueType,user,vendorOrder,vendorOrder.category,vendorOrder.brand,inquiryComment,inquiryComment.user",
        read_status: 1,
      };
      getDetails(id, params)
        .then((res) => {
          this.details = res;
          this.selectedStatus = this.details.issueStatus.display_name;
          this.isApiCalling = false;
        })
        .catch((err) => {
          this.isApiCalling = false;
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },
    refreshComments() {
      this.getIssueById(this.$route.params.id);
    },
    resolved() {
      this.savingStatus = true;
      const params = {
        inquiry_status_id: this.selectedStatus,
      };
      const id = this.details.id;
      update(id, params)
        .then(() => {
          this.savingStatus = false;
          this.$shilp.notify({
            title: "Issue Status Updated.",
            type: "success",
          });
          this.getIssueById(this.$route.params.id);
        })
        .catch((err) => {
          this.savingStatus = false;
          console.log(err);
        });
    },
    getCityNames(cities) {
      return cities.map((city) => city.name).join(", ");
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  @apply font-medium text-gray-500;
}

.value {
  @apply text-base font-medium text-gray-600;
}

::v-deep .vs__dropdown-option {
  margin: 9px;
}

.select-status{
  ::v-deep .v-select {
  ul {
    position: relative !important;
  }
}
}
</style>