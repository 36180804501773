// Formatting price
export const formatPrice = (price, notation = "standard") => {
  return new Intl.NumberFormat("en-IN", { style: "currency", notation: notation, currency: "INR", currencyDisplay: "symbol", maximumFractionDigits: 2 }).format(price);
};

export const getGradeAttributeObject = (attributeOptions) => {
  for (let item of attributeOptions) {
    if (item.attribute.id == 1) return item;
  }
};

export const getAttributeValue = (attributeOptions, attributeId) => {
  if (!(attributeOptions && attributeOptions.length)) return "";

  for (let item of attributeOptions) {
    if (item.attribute.id == attributeId) return item.name;
  }

  // In case it does not find  which will rarely occur
  return "";
};

import moment from "moment";
export const formatDate = (value, format='DD-MM-YYYY, h:mm A') => {
  if (value) {
    // Time is in seconds from Backend. so We have to convert in into milliseconds.
    const date = new Date(value * 1000);
    return moment(date).format(format);
  }
};

export const getReadClassList = (readStatus) => {
  if (readStatus) return [];
  else return ["unread-effect"];
};

export const getNotificationRoute = (type) => {
  switch (type) {
    case "Order":
    case "InvoiceRequest":
      return "order-details";
    case "Inquiry":
      return "inquiry-details";
    case "User":
    case "KYC":
      return "company-profile";
    case "admin_vendor_bid":
      return "vendor-bids-assign";
    case "admin_vendor_order":
    case "Vendor Order":
    case "admin_payment":
      return "vendor-order-details";
    case "admin_issue":
    case "Vendor Issue":
      return "vendor-inquiry-details";
  }
};

export const alertApiError = (error, instance) => {
  const message = error.data?.message;
  instance.$shilp.notify({
    title: message ? message : "Something went wrong.",
    type: "danger",
  });
};

export const cloneDeepItem = (item) => {
  return JSON.parse(JSON.stringify(item));
};
