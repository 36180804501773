<template>
  <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" ref="item" :get="getItem" :item="id" :footer="false" :save="saveItem">
    <template #default="{ form }">
      <div class="flex flex--fit items-center gap-2">
        <s-field-validate label="Location" rules="required" name="City" class="w-4/12">
          <tree-select v-model="form.vendor_product_location" :multiple="true" :options="allLocations" valueFormat="object" label="name" placeholder="Select your Locations" :normalizer="normalizer">
            <template #before-list>
              <input
                type="checkbox"
                :checked="form.vendor_product_location && form.vendor_product_location.length == allLocations.length"
                id="selectAll"
                class="ml-[28px] mr-2 custom-checkbox"
                @input="selectAllLocations($event, form)"
              />
              <label for="selectAll">Select All</label>
            </template>
          </tree-select>
        </s-field-validate>
        <s-field-validate class="grow w-4/12" label="Category" rules="required" name="Category">
          <v-select :options="categoryOption" placeholder="Select" v-model="form.category_id" :reduce="(category) => category.value" label="label" @input="categorySelected(form)" />
        </s-field-validate>
        <s-field-validate class="grow w-[31.5%]" label="Brand" rules="required" name="Brand">
          <BrandSelect :multiple="true" :params="form.category_id" @input="setBrandId" v-model="selectBrand" />
        </s-field-validate>
      </div>
      <div class="flex justify-end">
        <s-button type="submit" label="Add" color="primary" class="button--sm mr-2" :loader="submitLoader" />
        <s-button type="button" @click.native="cancel" label="Cancel" color="danger" class="button--sm" />
        </div>
    </template>
  </item-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BrandSelect from "@/components/brand/BrandSelect.vue";
import { get } from "@/api/vendor/vendor.js";
import dropdownOptions from "@/data/options";
import { getLocations } from "@/api/vendor/vendor.js";
import { addLocation } from "@/api/location.js";
import { getBrands } from "@/api/brand.js";
export default {
  params: {
    limit: 25,
  },
  props: ["id", "categoryDetail"],
  data() {
    return {
      brandOptions: [],
      categoryOptions: [],
      selectBrand: [],
      allLocations: [],
      isBrandsLoading: false,
      dropdownOptions,
      isLoading: false,
      submitLoader: false,
      fields: ["vendor_product_location", "category_id", "brand_ids"],
      formData: null,
      normalizer(node) {
        return {
          id: node.name + "+" + node.id,
          label: node.name,
          children: node.cities,
        };
      },
    };
  },
  components: {
    BrandSelect,
  },
  computed: {
    ...mapGetters({
      categoryOption: "categoryOptions",
      
    }),
    ...mapMutations({
      setBrandId: "order/setBrandId",
    }),
  },
  created() {},
  mounted() {
    this.getAllLocations();
  },

  watch: {
    categoryDetail(value) {
      if (value.category.id) {
        this.$refs.item.reset();
        this.selectBrand = []
        let selectedLocations = [];
        let matchedState = this.allLocations.find((res) => value.city.state_id === res.id);
        let matchedCity = matchedState.cities.find((res)=> value.city.id == res.id);
        if (matchedCity) {
          selectedLocations.push(matchedCity);
        }
        const categoryMatch = this.categoryOption.find((res) => res.value == value.category.id);
        this.formData = {
          vendor_product_location: selectedLocations,
          category_id: categoryMatch.value,
        };
        value.category.location_brands.map((res) => this.selectBrand.push(res.id));
        this.$refs.item.setForm(this.formData);
      }
    },
  },

  methods: {
    getItem(id) {
      return get(id).then((res) => {
        return {
          ...res,
          role: res.role.length ? res.role[0] : null,
        };
      });
    },
    async filterLocation(locations) {
      let filterId = [];

      if (Array.isArray(locations.vendor_product_location)) {
        filterId = await locations.vendor_product_location.flatMap((location) => {
          if (Array.isArray(location.cities)) {
            return location.cities.map((city) => city.id);
          } else {
            return location.id;
          }
        });

        this.getAllBrands(locations?.category_id, filterId);
      }
    },

    categorySelected(form) {
      form.brand_ids = null;
      this.filterLocation(form);
    },

    selectAllLocations(event, form) {
      if (event.target.checked) {
        form.vendor_product_location = this.allLocations;
      } else {
        form.vendor_product_location = [];
      }
    },

    getAllLocations() {
      const params = {
        limit: -1,
        include: "cities",
      };
      getLocations(params)
        .then((res) => {
          this.allLocations = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllBrands(category_id, filterId) {
      let params = {
        filter: {
          category_id: category_id,
          city_has: filterId.join(","),
        },
        limit: -1,
      };
      if (category_id) {
        getBrands(params).then((res) => {
          if (res?.data?.length) {
            this.selectBrand = res.data.map((item) => item?.id);
          } else {
            this.selectBrand = [];
          }
        });
      } else {
        this.selectBrand = [];
      }
    },
    async saveItem(id, form) {
      this.submitLoader = true;
      const postData = await this.generatePostData(form);
      return addLocation(postData)
        .then((res) => {
          this.$emit("locationAdded");
          this.submitLoader = false;
          this.selectBrand = [];
          this.$shilp.notify({
            title: res.message,
            type: "success",
          });
        })
        .catch(() => {
          this.submitLoader = false;
        });
    },

    generatePostData(form) {
      const states = {};
      form.vendor_product_location.forEach((location) => {
        if (location.cities) {
          location.cities.forEach((city) => {
            if (!states[city.state_id]) {
              states[city.state_id] = { id: city.state_id, city_ids: [] };
            }
            states[city.state_id].city_ids.push(city.id);
          });
        } else {
          if (!states[location.state_id]) {
            states[location.state_id] = { id: location.state_id, city_ids: [] };
          }
          states[location.state_id].city_ids.push(location.id);
        }
      });
      const result = Object.values(states);
      return {
        brand_ids: this.selectBrand,
        category_id: form.category_id,
        states: result,
      };
    },

    cancel() {
      this.$refs.item.reset();
      this.selectBrand = []
      this.selectedLocations = [];
    }
  },
};
</script>

<style scoped>
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  background-color: white;
}

.custom-checkbox:checked {
  background-color: #4a90e2;
  border-color: #4a90e2;
  color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m9 20.42l-6.21-6.21l2.83-2.83L9 14.77l9.88-9.89l2.83 2.83z'/%3E%3C/svg%3E");
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
}
</style>