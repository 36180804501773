// import Echo from "laravel-echo";
// window.Pusher = require("pusher-js");
import Pusher from "pusher-js";
import bus from "@/bus";

import notificationSound from "@/assets/sound/notification-sound.mp3";
const audio = new Audio(notificationSound);

const pusher = new Pusher(process.env.VUE_APP_MIX_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
});
const buildInquiryChannel = pusher.subscribe("build-inquiry");

const orderInquiryChannel = pusher.subscribe("build-order");

const newRegistrationChannel = pusher.subscribe("build-registration");

const kycStatusChangeChannel = pusher.subscribe("build-kyc-status-change");

const invoiceRequestChannel = pusher.subscribe("build-invoice-request");

const vendorBidChannel = pusher.subscribe("build-vendor-bid");

const vendorIssueChannel = pusher.subscribe("build-vendor-issue");

const vendorOrderChannel = pusher.subscribe("build-vendor-order");

newRegistrationChannel.bind("registration", (data) => {
  notifyNotifications(data);
  bus.$emit('new-registration', data)
});

buildInquiryChannel.bind("inquiry", (data) => {
  notifyNotifications(data);
});

orderInquiryChannel.bind("order", (data) => {
  notifyNotifications(data);
  bus.$emit('new-order-arrived', data)
});

kycStatusChangeChannel.bind("kycstatus", (data) => {
  notifyNotifications(data);
  bus.$emit('kyc-status-changed', data)
});

invoiceRequestChannel.bind("invoicerequest", (data) => {
  notifyNotifications(data);
});

vendorBidChannel.bind("vendor-bid", (data) => {
  notifyNotifications(data);
  bus.$emit('new-bid-arrived', data)
});

vendorIssueChannel.bind("vendor-issue", (data) => {
  notifyNotifications(data);
});

vendorOrderChannel.bind("vendor-order", (data) => {
  notifyNotifications(data);
});


function notifyNotifications(data) {
  bus.$toast.success(data.message, {
    position: "top-right",
    timeout: 5000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
  });

  audio.play();

  bus.$emit("notification-arrived", data);
}

// console.log("processs", process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER);
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
//   cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
//   forceTLS: false,
//   authEndpoint: "https://api-bii.preview.im/broadcasting/auth",
// });
