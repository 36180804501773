import { render, staticRenderFns } from "./PaymentInfo.vue?vue&type=template&id=55b6b4d8&scoped=true"
import script from "./PaymentInfo.vue?vue&type=script&lang=js"
export * from "./PaymentInfo.vue?vue&type=script&lang=js"
import style0 from "./PaymentInfo.vue?vue&type=style&index=0&id=55b6b4d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55b6b4d8",
  null
  
)

export default component.exports