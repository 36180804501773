<template>
  <div class="card-wrap p-2" v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500 font-bold">Order Count</h4>
    <div v-if="series.every((item) => item === 0)" class="noData">
      <div>No Data Available</div>
    </div>
    <VueApexChart v-else width="480" height="280" type="donut" :options="options" :series="series" />
  </div>
</template>

<script>
import VueApexChart from "vue-apexcharts";
import { getCountForAllOrders } from "@/api/order";
import orderStatusData from "@/data/orderStatus";
import { mapMutations } from "vuex";
export default {
  props: ["dateRange"],
  components: {
    VueApexChart,
  },
  created() {
    this.getAllCount();
    this.$root.$on("dashboard-refresh", this.getAllCount);
  },
  beforeDestroy() {
    this.$root.$off("dashboard-refresh", this.getAllCount);
  },
  watch: {
    dateRange() {
      this.getAllCount();
    },
  },
  computed: {
    series() {
      return this.counts;
    },
    params() {
      return {
        filter: {
          date_range: this.dateRange,
        },
      };
    },
  },
  data() {
    return {
      isLoading: true,
      counts: [],

      options: {
        chart: {
          width: 480,
          height: 280,
          type: "donut",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.resetFilters();
              let orderStatus = chartContext?.w.config.labels[config.dataPointIndex];
              if (orderStatus) {
                if (orderStatus == "Cancelled") {
                  this.setOrderStatusId(orderStatusData.cancelled);
                } else if (orderStatus == "Delivered") {
                  this.setOrderStatusId(orderStatusData.delivered);
                } else if (orderStatus == "Under Process") {
                  this.setOrderStatusId(orderStatusData.under_process);
                }

                this.$router.push({ name: "order-list" });
              }
            },
          },
        },
        colors: ["#008ffb", "#00e396", "#ff0000"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: function (val, { seriesIndex, w }) {
            return w.config.series[seriesIndex];
          },
        },
        labels: ["Under Process", "Delivered", "Cancelled"],
        legend: {
          show: true,
          fontSize: "16px",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  methods: {
    ...mapMutations({
      setOrderStatusId: "order/setOrderStatusId",
      resetFilters: "order/resetFilters",
    }),
    getAllCount() {
      this.isLoading = true;
      getCountForAllOrders(this.params)
        .then(({ under_process_order_count, delivered, cancelled }) => {
          // this.totalCount = total;
          // this.underProcessCount = under_process_order_count;
          // this.deliveredCount = delivered;
          // this.cancelledCount = cancelled;
          // this.overDueCount = overdue_count;

          this.counts = [under_process_order_count, delivered, cancelled];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.noData {
  width: 480px;
  height: 280px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    margin-top: 30px;
  }
}
::v-deep .apexcharts-slices{
  @apply cursor-pointer;
}
</style>
