<template>
  <div>
    <div class="approved-list-filter-top">
      <ActiveCountCard @selected="filters.days = $event" />

      <div class="card-wrap">
        <label for="" class="field__label">Company Type</label>
        <v-select label="displayName" class="v-select" :searchable="false" placeholder="Select" v-model="filters.company_type" :options="companyTypeOptions" :reduce="(item) => item.id" />
      </div>
    </div>

    <DataTable
      status="approve"
      :filters="filters"
      @date-filter-selected="filters.date_range = $event"
      @resetFilters="resetFilters"
      @status-filter-selected="filters.status = $event"
      @search="filters.search = $event"
      @buyerTypeFilterSelected="filters.buyer_type_id = $event"
      @active-company-filter-selected="filters.active_company = $event ? $event : null"
      @company-create-admin="filters.company_create_admin = $event ? $event : null"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    ActiveCountCard: require("@/components/company/ActiveCountCard.vue").default,
    DataTable: require("@/components/company/DataTable.vue").default,
  },
  computed: {
    ...mapGetters({
      companyTypeOptions: "companyTypeOptions",
    }),
  },
  data() {
    return {
      filters: {
        days: null,
        kyc_status: "approve",
        status: null,
        search: null,
        date_range: null,
        buyer_type_id: null,
        company_type: null,
        active_company: null,
        company_create_admin: null,
      },
    };
  },
  created() {
    if (this.$route.query.activeCompanyfilter) {
      this.filters.active_company = 60;
    }
    if (this.$route.query.date_range) {
      this.filters.date_range = this.$route.query.date_range;
    }
  },
  methods: {
    resetFilters() {
      this.filters = {
        days: null,
        kyc_status: "approve",
        status: null,
        search: null,
        date_range: null,
        buyer_type_id: null,
        company_type: null,
        active_company: null,
        company_create_admin: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.approved-list-filter-top {
  @apply flex gap-4 items-center;

  .v-select {
    min-width: 250px;
  }
}
</style>
