<template>
  <v-select
    label="name"
    :multiple="multiple"
    :close-on-select="!multiple"
    placeholder="Select"
    :options="brandOptions"
    :reduce="(brand) => brand.id"
    :value="value"
    @input="$emit('input', $event)"
    @change="updateValue"
  >
    <template #option="{ name, logo, id }">
      <label class="flex items-center cursor-pointer">
        <template v-if="multiple">
          <div class="relative flex items-center">
            <input type="checkbox" :checked="isOptionSelected(id)" class="mr-2" @click.stop @change="handleCheckboxChange(id)" />
          </div>
        </template>
        <img v-if="logo && logo.url" :src="logo.url" class="w-4 h-4 object-contain mr-3 inline-block" />
        <span class="leading-6">{{ name }}</span>
      </label>
    </template>
  </v-select>
</template>

<script>
import { getBrands } from "@/api/brand.js";

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    params: [Object, Number, Array],
    category:{
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      brandOptions: [],
      internalValue: [],
    };
  },

  created() {
    this.internalValue = Array.isArray(this.value) ? [...this.value] : [];
  },

  watch: {
    value: {
      handler(newVal) {
        this.internalValue = Array.isArray(newVal) ? [...newVal] : [];
      },
      deep: true,
    },
    params(value) {
      this.getAllBrands(value);
    },
  },

  mounted() {
    this.getAllBrands();
  },

  methods: {
    getAllBrands(id = null) {
      let params = {
        filter: {
          category_id: this.category ? id?.join(",") : id,
        },
        limit: -1,
      };
      getBrands(params).then((res) => {
        this.brandOptions = res.data;
      });
    },

    isOptionSelected(id) {
      if (this.multiple) {
        return this.internalValue.includes(id);
      }
      return this.value === id;
    },

    handleCheckboxChange(id) {
      if (this.multiple) {
        const currentIndex = this.internalValue.indexOf(id);
        const newSelection = [...this.internalValue];

        if (currentIndex === -1) {
          newSelection.push(id);
        } else {
          newSelection.splice(currentIndex, 1);
        }

        this.internalValue = newSelection;
        this.$emit("input", newSelection);
        this.$emit("change", newSelection);
      } else {
        this.$emit("input", id);
        this.$emit("change", id);
      }
    },

    updateValue(event) {
      if (this.multiple && Array.isArray(event)) {
        const selectedValues = event.map((option) => (typeof option === "object" ? option.id : option));
        this.internalValue = selectedValues;
        this.$emit("input", selectedValues);
      } else if (event) {
        const value = typeof event === "object" ? event.id : event;
        this.$emit("input", value);
      }
    },
  },
};
</script>

<style scoped>
.v-select {
  position: relative;
}
</style>