import $axios from "@/plugins/axios";

export const getCollection = (params = null) => {
  return $axios.get("companies", { params }).then((res) => {
    return res.data.projects;
  });
};

export const getAll = (params = null) => {
  return $axios.get("projects", { params }).then((res) => {
    return res.data;
  });
};

export const updateGeoLocation = (id, data) => {
  return $axios.put(`projects/${id}/geo-map`, data).then((res) => {
    return res.data;
  });
};