<template>
  <s-button icon="IconMdiBellRing" :color="blinkBell ? 'secondary' : 'grey'" theme="link" size="xl" :class="blinkBell ? 'animate-pulse' : ''">
    <template #after v-if="notificationCount">
      <span class="text-md">
        <sup>{{ notificationCount }}</sup></span
      >
    </template>
  </s-button>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    notifications: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    blinkBell() {
      return this.notificationCount ? true : false;
    },

    notificationCount() {
      return this.notifications.total;
    },
  },
  created() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
