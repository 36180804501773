export default [
    {
        path: "category",
        name: "category",
        component: require("@/views/private/master/category/Index").default,
        meta: {
            label: "Category",
        },
        redirect: { name: "category-listing" },
        children: [
            {
                path: "list",
                name: "category-listing",
                component: require("@/views/private/master/category/List").default,
                meta: {
                    label: "Category",
                    breadcrumbs: ["dashboard", "category-listing"],
                },
            },
            {
                path: ":id",
                props: true,
                name: "manage-category",
                component: require("@/views/private/master/category/Item").default,
                meta: {
                    label: "Add New",
                    breadcrumbs: ["dashboard", "category-listing", "manage-category"],
                },
            },
        ],
    },
];
