<template>
  <div>
    <div class="inquiry-filter-wrap">
      <CompanyFilter v-if="module == 'master'" @selected="setCompanyFilter" class="company-filter bg-white py-3 px-4 mb-4 rounded-md" @cleared="resetCompanyState" />
      <s-field label="Vendor Name" v-if="module == 'vendor'" class="company-filter bg-white py-3 px-4 mb-4 rounded-md">
        <v-select :options="vendorsOptions" label="name" :reduce="(vendors) => vendors.id" v-model="filters.user_id" placeholder="Select" :clearable="true" @option:selected="onCompanySelected">
          <template #footer v-if="vendorAddress">
            <p class="text-grey font-medium text-sm line-clamp-2 mt-2" v-tooltip="vendorAddress">
              {{ vendorAddress }}
            </p>
          </template>
        </v-select>
      </s-field>

      <s-field label="Date" class="card-wrap py-3 px-4 mb-4">
        <CustomDatePicker v-model="filters.date_range" :clearable="true" />
      </s-field>

      <s-field :label="module == 'vendor' ? 'Issue Status' : 'Inquiry Status'" class="card-wrap inquery py-3 px-4 mb-4">
        <v-select :options="inquiryStatusOptions" placeholder="Select" :reduce="(item) => item.id" class="w-60 status-field" v-model="filters.inquiry_status_id" :clearable="true">
          <template v-slot:option="inquiryStatusOptions">
            <StatusTag :label="inquiryStatusOptions.label" :name="inquiryStatusOptions.name == 'Under Process' ? 'under_process' : inquiryStatusOptions.name.toLowerCase()" />
          </template>
        </v-select>
      </s-field>
    </div>

    <sp-list class="h-100 inquiry" ref="list" sortBy="created_at" sortOrder="desc" :filters="filters" :perPage="params.limit" :attrs="columns" :params="params" :endpoint="endpoint">
      <template #header="{ refresh }">
        <CategoryTabs @selected="onSelectCategory" @unread-filter-selected="onUnreadFilterSelected()" ref="categoryTabs" :module="module" :filters="filters" />

        <div class="filter-settings-panel justify-end">
          <SearchFilter @search="filters.search = $event" />
          <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refreshCounts(refresh)" />
        </div>
      </template>
      <template #empty>
        <list-empty />
      </template>
      <!-- <template #filters>
        <div class="text-center mt-4">
          <s-button label="Clear" icon="IconMdiFilterRemoveOutline" color="danger" theme="muted" @click.native="resetFilters" />
        </div>
      </template> -->

      <template #default>
        <portal to="header-actions" v-if="module != 'vendor'">
          <s-button label="Export" color="secondary" @click.native="exportToExcel" :loader="isExporting" />
        </portal>
        <sp-list-table @rowClick="redirectTo($event)">
          <template #type="{ item }">
            <Tag :label="getInquiryType(item.inquiry_type).label" />
          </template>

          <template #name="{ item }">
            <span>{{ item.name }}</span>
          </template>

          <template #category="{ item }">
            <span>{{ item.category }} {{ item.brand ? ` / ${item.brand}` : "" }} {{ item.grade ? ` / ${item.grade}` : "" }} {{ item.size_packing ? ` / ${item.size_packing}` : "" }}</span>
          </template>

          <template #additional_requirement="{ item }">
            <span v-if="item.additional_requirement" class="additional-requirement" v-tooltip="item.additional_requirement">
              {{ item.additional_requirement }}
            </span>
          </template>

          <template #attachment="{ item }">
            <template v-for="attachment in item.attachment">
              <s-button
                :key="`attachment-${attachment.id}`"
                icon="IconMdiFileDocumentMultipleOutline"
                size="sm"
                shape="circle"
                color="grey"
                theme="muted"
                @click.native="open(attachment.url, '_blank')"
              />
            </template>
          </template>

          <template #status="{ item }">
            <Tag :label="item.inquiryStatus ? item.inquiryStatus.name : 'Open'" />
          </template>

          <template #issueStatus="{ item }" v-if="module == 'vendor'">
            <StatusTag :label="item.issueStatus.name" :name="item.issueStatus.name == 'Under Process' ? 'under_process' : item.issueStatus.name.toLowerCase()" v-if="item.issueStatus" />
          </template>

          <template #vendor_issue_type="{ item }">
            <span>{{ item.vendor_issue_type.display_name }}</span>
          </template>
          <template #user="{ item }">
            <span>{{ item.user.name }}</span>
          </template>
          <template #vendor_order_no="{ item }">
            <span>{{ item.vendor_order_no || "NA" }}</span>
          </template>
          <template #issue_no="{ item }">
            <span>{{ item.issue_no || "NA" }}</span>
          </template>
        </sp-list-table>
      </template>
    </sp-list>
  </div>
</template>

<script>
import { getInquiryType } from "@/data";
import { exportExcel } from "@/api/inquiry";
import bus from "@/bus";
import { getAll } from "@/api/vendor/vendor.js";
import StatusTag from "@/components/StatusTag.vue";

export default {
  props: {
    inquiryStatusOptions: Array,
    endpoint: String,
    params: Object,
    filter: Object,
    columns: Array,
    module: String,
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    CategoryTabs: require("@/components/inquiry/CategoryTabs.vue").default,
    CompanyFilter: require("@/components/company/Filter.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    CustomDatePicker: require("@/components/CustomDatePicker.vue").default,
    StatusTag,
  },

  data() {
    return {
      filters: this.filter,
      isExporting: false,
      vendorsOptions: [],
      vendorAddress: null,
    };
  },
  created() {
    bus.$on("notification-arrived", () => {
      this.refresh();
    });
  },
  mounted() {
    this.getAllVendors();
  },
  destroyed() {
    bus.$off("notification-arrived", this.refresh);
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.module == "vendor") {
          this.getVendorAddress(this.filters.user_id);
          this.$refs.categoryTabs.getIssueCount();
        } else {
          this.$refs.categoryTabs.getCount();
        }
      },
    },
  },
  methods: {
    getInquiryType,
    refresh() {
      this.$refs.list.refresh();
    },
    setCompanyFilter(id) {
      this.filters.company_id = id;
    },
    onCompanySelected(event) {
      this.filters.user_id = event.id;
    },
    toggleSidebar() {
      this.$refs.list.toggleSidebar("filters");
    },
    getVendorAddress(id) {
      const vendor = this.vendorsOptions.find((vendor) => {
        return id == vendor.id;
      });
      this.vendorAddress = vendor && vendor.vendorDetail ? vendor.vendorDetail.vendor_office_address : "";
    },

    refreshCounts(refresh) {
      refresh();
      if (this.module == "vendor") {
        this.$refs.categoryTabs.getIssueCount();
      } else {
        this.$refs.categoryTabs.getCount();
      }
    },
    exportToExcel() {
      this.isExporting = true;
      exportExcel({ filter: this.filters })
        .then((res) => {
          this.open(res.url);
        })
        .catch()
        .finally(() => (this.isExporting = false));
    },

    resetCompanyState() {
      this.filters.company_id = null;
    },

    open(url) {
      window.open(url, "_blank");
    },

    onUnreadFilterSelected() {
      if (this.module == "vendor") {
        this.filters.read_status = 0;
        this.filters.vendor_issue_type_id = null;
      } else {
        this.filters.read_status = 0;
        this.filters.inquiry_type = null;
      }

      // this.refresh();
    },

    getAllVendors() {
      getAll({ "filter[role]": "vendor" }).then((res) => {
        this.vendorsOptions = res.data;
      });
    },

    onSelectCategory(event) {
      if (this.module == "vendor") {
        this.filters.vendor_issue_type_id = event;
        this.filters.read_status = null;
      } else {
        this.filters.inquiry_type = event;
        this.filters.read_status = null;
      }
    },

    refreshIssueList() {
      this.$refs.list.refresh();
    },

    redirectTo(event) {
      const routerName = this.module == "vendor" ? "vendor-inquiry-details" : "inquiry-details";
      this.$router.push({ name: routerName, params: { id: event.id } });
    },

    // company_id: null,
    // resetFilters() {
    //   this.filters = {
    //     inquiry_type: null,
    //     read_status: null,
    //     search: null,
    //     date_range: null,
    //     inquiry_status_id: null,
    //   };
    // },
  },
};
</script>

<style lang="scss">
.v-list.inquiry {
  .v-list__custom-header {
    > * {
      @apply flex-auto;
    }
  }
}
.additional-requirement {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @apply text-ellipsis overflow-hidden;
}

.filter-settings-panel {
  @apply flex gap-2 mb-2;

  .refresh,
  .cog {
    @apply flex-shrink-0;
  }
}
.inquiry-filter-wrap {
  @apply flex gap-4;
}
</style>
