import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import draggable from 'vuedraggable'

Vue.component("ItemForm", require("@/components/form/ItemForm").default);
Vue.component("Tag", require("@/components/Tag").default);
Vue.component("Modal", require("@/components/Modal").default);
Vue.component("StatusInput", require("@/components/StatusInput").default);
Vue.component("ListEmpty", require("@/components/ListEmpty").default);
Vue.component("Separator", require("@/components/Separator").default);
Vue.component("DatePicker", DatePicker);
Vue.component("TreeSelect", Treeselect);
Vue.component("draggable", draggable);

/**
 * Auto Register Icons
 */
const icons = require.context("./components/icons/", true, /\.vue$/i);
icons.keys().map((key) => {
  const path = key.split("/");
  const name = path[path.length - 1].split(".")[0];
  return Vue.component("Icon" + name, icons(key).default);
});
