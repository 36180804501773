<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add Vendor" icon="IconIcRoundPlus" :to="{ name: 'vendor-create', params: { id: '+' } }" />
      </div>
    </portal>

    <div>
      <FilterBar @resetFilters="resetFilters" @applyBtn="applyBtn">
        <s-field label="States">
          <v-select
            :options="allStates"
            :multiple="true"
            label="name"
            :reduce="(state) => state.id"
            v-model="filters.vendor_state_id"
            placeholder="Select"
            :clearable="false"
            @input="handleStateRemove"
          />
        </s-field>
        <s-field label="Cities" class="mt-2">
          <v-select :options="allCities" ref="cityRef" :multiple="true" label="name" :reduce="(city) => city.id" v-model="filters.vendor_city_id" placeholder="Select" :clearable="false" />
        </s-field>
        <s-field label="Categories" class="mt-2">
          <v-select
            :options="categoryOptions"
            label="name"
            :reduce="(category) => category.id"
            v-model="filters.vendor_category_id"
            placeholder="Select"
            :clearable="false"
            :multiple="true"
            @input="categorySelected(filters)"
          />
        </s-field>
        <s-field label="Brand" v-shilp-loader.overlay="isBrandsLoading" class="mt-2 mb-2">
          <BrandSelect :multiple="true" v-model="filters.vendor_brand_id" :params="filters.vendor_category_id" :category="true" @input="setBrandId($event)" />
        </s-field>
        <s-field label="Company Name">
          <v-select :options="vendorsOptions" :multiple="true" label="name" :reduce="(vendors) => vendors.id" v-model="filters.id" placeholder="Select" :clearable="false" />
        </s-field>
      </FilterBar>

      <sp-list sortBy="created_at" sortOrder="desc" ref="list" class="h-100" :filters="filters" :perPage="$options.params.limit" :params="$options.params" :attrs="columns" endpoint="vendors">
        <template #empty>
          <list-empty />
        </template>
        <template #header="{ refresh }">
          <div class="mb-4 flex flex--fit justify-end w-full">
            <SearchFilter :value="filters.search" @search="filters.search = $event" class="mr-2" />
            <div>
              <s-button class="filter mr-2" icon="vmdi-filter" shape="circle" @click.native="$root.$emit('filter-bar-open')" />
              <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
            </div>
          </div>
        </template>
        <sp-list-table @rowClick="$router.push({ name: 'vendor-edit', params: { id: $event.id } })">
          <template #category="{ item }">
            {{ getCategories(item) || "NA" }}
          </template>
          <template #location="{ item }">
            <v-popover trigger="click" popoverWrapperClass="w-[300px]">
              <template #popover>
                <div v-for="(state, index) in item.states" :key="state.state_name">
                  <p class="word-wrap">{{ index + 1 }} - {{ state.state_name }}: {{ state.city_names }}</p>
                  <br />
                </div>
              </template>
              <StatusTag class="location-chip mr-1" :label="`States: ${item.total_states}`" />
              <StatusTag class="location-chip" :label="`Cities: ${item.total_cities}`" />
            </v-popover>
          </template>
          <template #assign="{ item }">
            <v-popover popoverWrapperClass="w-[300px]" :ref="`assign-popover-${item.id}`">
              <template #popover>
                <AssignTeamMember
                  :key="`assign-dropdown-${item.id}`"
                  :company-id="item.id"
                  @assigned="afterAssignMember(item.id)"
                  class="assign-team"
                  :team-member="item.vendorDetail && item.vendorDetail.assignUser && item.vendorDetail.assignUser.id"
                  module="vendor"
                />
              </template>
              <s-button :key="`assign-btn-${item.id}`" title="Assign team member to company" icon="IconMdiAccountPlusOutline" color="grey" theme="muted" shape="circle" />
            </v-popover>
          </template>
          <template #vendorDetail="{ item }">
            {{ (item.vendorDetail && item.vendorDetail.assignUser && item.vendorDetail.assignUser.name) || "NA" }}
          </template>
          <template #product="{ item }">
            <a class="bg-orange-100 product-icon flex justify-center content-center rounded-full cursor-pointer" @click="$router.push({ name: 'vendor-product', params: { id: item.id } })">
              <s-icon name="IconMdiEyeOutline" class="text-orange-500" />
            </a>
          </template>
          <template #created_at="{item}">
            {{ formatDate(item.created_at) }}
          </template>
        </sp-list-table>
      </sp-list>
    </div>
  </div>
</template>

<script>
import BrandSelect from "@/components/brand/BrandSelect.vue";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash-es";
import StatusTag from "@/components/StatusTag.vue";
import { formatDate, getReadClassList } from "@/utilities/common";
import { getAll, getLocations, getAllCities } from "@/api/vendor/vendor.js";
import { remove } from "lodash-es";
export default {
  params: {
    limit: 20,
    include: "vendorDetail.assignTo",
  },
  data() {
    return {
      defautFilter: {
        search: null,
        role: "vendor",
        vendor_brand_id: null,
        vendor_category_id: null,
        id: null,
        vendor_state_id: null,
        vendor_city_id: null,
      },
      filters: {},
      brandOptions: [],
      categoryOptions: [],
      isBrandsLoading: false,
      isApplyBtnClicked: false,
      vendorsOptions: [],
      allStates: [],
      allCities: [],
      columns: [
        {
          name: "name",
          label: "Company Name",
        },
        "category",
        {
          name: "location",
          label: "Location",
          rowClick: false,
          width: "200px",
        },
        {
          name: "vendorDetail",
          label: "Assign to",
        },
        {
          name: "created_at",
          label: "Date & Time",
          sortable: true,
        },
        {
          name: "assign",
          label: "Assign",
          rowClick: false,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "product",
          label: "Product",
          rowClick: false,
        },
      ],
    };
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    FilterBar: require("@/components/FilterBar").default,
    AssignTeamMember: require("@/components/company/AssignTeamMember.vue").default,
    BrandSelect,
    StatusTag,
  },
  computed: {
    ...mapGetters({
      categoryOption: "categoryOptions",
    }),
    ...mapMutations({
      setBrandId: "order/setBrandId",
    }),
  },
  created() {
    this.filters = cloneDeep(this.defautFilter);
  },
  mounted() {
    formatDate,
    this.modifyCategoryOption();
    this.getAllVendors();
    this.getStates();
    this.getCities(null);
    this.filters.vendor_city_id = this.$route.query.cityId;
    this.filters.vendor_category_id = this.$route.query.categoryId;
  },

  watch: {
    "filters.vendor_state_id"(value) {
      this.getCities(value);
    },
  },

  methods: {
    formatDate,
    getReadClassList,
    resetFilters() {
      this.filters = cloneDeep(this.defautFilter);
    },

    getAllVendors() {
      const params = {
        limit: -1,
        "filter[role]": "vendor",
      };
      getAll(params).then((res) => {
        this.vendorsOptions = res.data;
      });
    },

    modifyCategoryOption() {
      this.categoryOption.map((res) => {
        this.categoryOptions.push({
          id: res.value,
          name: res.label,
        });
      });
    },

    applyBtn() {
      this.isApplyBtnClicked = true;
    },
    categorySelected(filters) {
      filters.vendor_brand_id = null;
    },

    getCategories(item) {
      let categories = [];
      item.categories.map((res) => {
        categories.push(res.name);
      });
      return categories.join(", ");
    },

    getStates() {
      const params = {
        limit: -1,
        sort: {
          by: "name",
          order: "asc",
        },
      };
      this.filters.vendor_city_id = null;
      getLocations(params)
        .then((res) => {
          this.allStates = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCities(value) {
      const params = {
        filter: {
          state_id: value,
        },
        limit: -1,
        sort: {
          by: "name",
          order: "asc",
        },
      };
      getAllCities(params)
        .then((res) => {
          this.allCities = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    afterAssignMember(id) {
      try {
        if (this.$refs[`assign-popover-${id}`]) {
          this.$refs[`assign-popover-${id}`].hide();
        }
        this.$refs.list.refresh();
      } catch (e) {
        console.log(e);
      }
    },

    handleStateRemove(event) {
      let filterdCities = [];
      let filterdCitiesId = [];
      event.map((res) => {
        filterdCities = remove(this.$refs.cityRef.selectedValue, (city) => {
          if (city.state_id == res) {
            return city.id;
          }
        });
      });
      filterdCities.map((res) => {
        filterdCitiesId.push(res.id);
      });
      this.filters.vendor_city_id = filterdCitiesId;
    },
  },
};
</script>

<style lang="scss" scoped>
.location-chip {
  background-color: #eceff1;
  color: black;
}
.word-wrap {
  word-wrap: break-word;
}
.product-icon {
  height: 30px;
  width: 30px;
}
::v-deep td.unread-effect {
  background-color: transparent !important;
}

 .assign-team{
  ::v-deep .v-select {
  ul {
    position: relative !important;
  }
}
}
</style>