<template>
  <div class="card-wrap p-2" v-shilp-loader.overlay="isLoading">
    <h4 class="text-primary-500 font-bold">Payment Statistics</h4>
    <VueApexChart width="480" height="280" type="donut" :options="options" :series="series" />
  </div>
</template>

<script>
import { getPaymentStatistics } from "@/api/payment";
import VueApexChart from "vue-apexcharts";
import { formatPrice } from "@/utilities/common";
import { mapMutations, mapGetters } from "vuex";
export default {
  props: ["dateRange"],
  components: {
    VueApexChart,
  },
  data() {
    return {
      series: [],
      labels: [],
      isLoading: false,
    };
  },
  watch: {
    dateRange() {
      this.getData();
    },
  },
  computed: {
    ...mapGetters({
      paymentStatusOptions: "paymentStatusOptions",
    }),
    options() {
      return {
        chart: {
          width: 480,
          height: 280,
          type: "donut",

          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.resetFilters();
              let paymentStatus = chartContext?.w.config.labels[config.dataPointIndex];
              if (paymentStatus) {
                this.setPaymentStatusId(this.paymentStatusOptions.find((item) => item.label == paymentStatus).value);

                this.$router.push({ name: "order-list" });
              }
            },
          },
        },
        colors: ["#008ffb", "#00e396", "#ff0000", "#feb019"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: function (val, { seriesIndex, w }) {
            return formatPrice(w.config.series[seriesIndex], "compact");
          },
        },
        labels: this.labels,
        legend: {
          show: true,
          fontSize: "16px",
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return formatPrice(value);
            },
          },
        },
        noData: {
          text: "No Data Available",
          align: "center",
          verticalAlign: "middle",
          style: {
            fontSize: "18px",
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  enabled: true,
                  formatter: function (value) {
                    const val = value.config.series.reduce((a, b) => a + b, 0);
                    return formatPrice(val);
                  },
                },
                name: {
                  enabled: true,
                  formatter: function (value) {
                    return value;
                  },
                },
                value: {
                  enabled: true,
                  formatter: function (val) {
                    return formatPrice(val);
                  },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },
  },
  created() {
    this.getData();
    this.$root.$on("dashboard-refresh", this.getData);
  },
  beforeDestroy() {
    this.$root.$off("dashboard-refresh", this.getData);
  },
  methods: {
    ...mapMutations({
      setPaymentStatusId: "order/setPaymentStatusId",
      resetFilters: "order/resetFilters",
    }),
    getData() {
      this.isLoading = true;
      getPaymentStatistics({
        filter: {
          date_range: this.dateRange,
        },
      })
        .then((res) => {
          this.series = [];
          this.labels = [];
          if (Object.keys(res).length) {
            this.labels = Object.keys(res);

            this.series = Object.values(res).map((item) => item.total_amount);
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .apexcharts-slices{
  @apply cursor-pointer;
}
</style>
