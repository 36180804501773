<template>
  <span :class="`p-2 radius-7 label font-medium order-tag-${name}`">
    <span v-if="emoji">{{ emoji }} &nbsp;</span>
    <span class="status-label">{{ label }}</span>
  </span>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    name: { type: String, default: null },
  },
  computed: {
    emoji() {
      let icon = "";
      switch (this.name) {
        case "pending":
        case "unpaid":
          icon = "⚠️";
          break;
        case "shipped":
        case "partial_shipped":
          icon = "🚚";
          break;
        case "confirmed":
        case "delivered":
        case "partial_delivered":
          icon = "✅";
          break;
        case "cancelled":
          icon = "❌";
          break;
        case "onhold":
          icon = "🚧";
          break;
        case "paid":
        case "partially_paid":
          icon = "💰";
          break;
        case "overdue":
          icon = "🚩";
          break;
      }

      return icon;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.label {
  font-size: smaller;
}
.order-tag-partial_delivered {
  background-color: #7e9f2033;
  color: #7e9f20;
}
.order-tag-partial_shipped,
.order-tag-others {
  background-color: #b7842233;
  color: #b78422;
}
.order-tag-cancelled,
.order-tag-overdue,
.order-tag-close,
.order-tag-expired,
.order-tag-lost,
.order-tag-closed,
.order-tag-cancel,
.order-tag-excluded {
  background-color: #f5e8e8;
  color: #ce3d3d;
}
.order-tag-onhold {
  background-color: #c23dce33;
  color: #c23dce;
}
.order-tag-request-for-brand,
.order-tag-confirmed {
  background-color: #7c3dce33;
  color: #7c3dce;
}
.order-tag-shipped,
.order-tag-under_process,
.order-tag-partial_paid,
.order-tag-quantity {
  background-color: #fef3db;
  color: #f57418;
}
.order-tag-pending,
.order-tag-account_modification {
  background-color: #3d65ce33;
  color: #3d65ce;
}

.order-tag-paid,
.order-tag-advance,
.order-tag-delivered,
.order-tag-open,
.order-tag-active,
.order-tag-partially_paid,
.order-tag-won,
.order-tag-included,
.order-tag-delivery,
.order-tag-payment {
  background-color: #e8f5e9;
  color: #388e3c;
}

.order-tag-unpaid,
.order-tag-submitted,
.order-tag-invoice {
  background-color: #eab30833;
  color: #eab308;
}
</style>