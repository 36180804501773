<template>
  <sp-list sortBy="created_at" sortOrder="desc" ref="list" class="h-100" :filters="filters" :perPage="params.limit" :params="params" :attrs="columns" endpoint="vendor/advanceBids">
    <template #empty>
      <list-empty />
    </template>
    <template #header="{ refresh }">
      <div class="flex flex--fit space-x-2">
        <s-field label="Vendor Name" class="w-56 bg-white rounded-md p-4">
          <v-select :options="vendorsOptions" label="name" :reduce="(vendors) => vendors.id" v-model="filters.user_id" placeholder="Select" :clearable="true" />
        </s-field>
        <s-field label="States" class="w-52 bg-white rounded-md p-4">
          <v-select :options="allStates" :multiple="true" label="name" :reduce="(state) => state.id" v-model="stateId" placeholder="Select" :clearable="true" @input="handleStateRemove" />
        </s-field>
        <s-field label="Cities" class="w-52 bg-white rounded-md p-4">
          <v-select :options="allCities" ref="cityRef" :multiple="true" label="name" :reduce="(city) => city.id" v-model="filters.city_id" placeholder="Select" :clearable="true" />
        </s-field>
        <s-field label="Categories" class="w-52 bg-white rounded-md p-4">
          <v-select
            :options="categoryOptions"
            label="name"
            :reduce="(category) => category.id"
            v-model="filters.category_id"
            placeholder="Select"
            :clearable="true"
            @input="categorySelected(filters)"
          />
        </s-field>
        <s-field label="Brand" v-shilp-loader.overlay="isBrandsLoading" class="w-56 bg-white rounded-md p-4">
          <BrandSelect :multiple="true" v-model="filters.brand_id" :params="filters.category_id" @input="setBrandId($event)" />
        </s-field>
      </div>

      <div class="flex flex--fit justify-between my-3 w-full">
        <s-button-group color="secondary" theme="tab" size="lg">
          <!-- <s-button label="All" @click.native="onSelectTab('all')" :active="selectedTab == 'all'" :class="{ 'text-secondary-500 font-bold': selectedTab == 'all' }" /> -->
          <s-button :label="`Active (${activeCounts})`" @click.native="onSelectTab('active')" :active="selectedTab == 'active'" :class="{ 'text-secondary-500 font-bold': selectedTab == 'active' }" />
          <s-button
            :label="`InActive (${inActiveCounts})`"
            @click.native="onSelectTab('inactive')"
            :active="selectedTab == 'inactive'"
            :class="{ 'text-secondary-500 font-bold': selectedTab == 'inactive' }"
          />
        </s-button-group>
        <div class="flex flex--fit">
          <SearchFilter :value="filters.search" @search="filters.search = $event" class="mr-2" />
          <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
        </div>
      </div>
    </template>
    <sp-list-table>
      <template #user="{ item }">
        {{ item.user && item.user.name || "NA" }}
      </template>
      <template #city="{ item }">
        <StatusTag class="location-chip" :label="item.city && item.city.name" />
      </template>
      <template #category="{ item }">
        {{ item.category.name || "NA" }}
      </template>
      <template #brand="{ item }">
        {{ item.brand.name || "NA" }}
      </template>
      <template #quantity="{ item }"> {{ item.quantity }} {{ item.unit_name }} </template>
      <template #price="{ item }">
        {{ formatPrice(item.price) || "NA" }}
      </template>
      <template #action="{ item }">
        <div class="flex flex--fit gap-2 items-center">
          <v-popover popoverWrapperClass="w-[300px]" :ref="`advance-bid-${item.id}`">
            <template #popover>
              <s-field-validate name="quantity" label="Enter Quantity" rules="required" class="field--required">
                <STextbox type="number" v-model.number="popoverQuantity" />
              </s-field-validate>
              <s-field-validate name="price" label="Enter Price" rules="required" class="field--required"> <STextbox type="number" v-model.number="popoverPrice" /> </s-field-validate>
              <s-button label="Submit" class="button--sm button--primary mt-2" :disabled="!popoverPrice || !popoverQuantity" @click.native="onSubmit(popoverPrice, popoverQuantity, item, 'popover')" />
            </template>
            <s-button :key="`popover-${item.id}`" icon="IconMdiPencilOutline" shape="circle" color="info" theme="muted" class="h-4 w-4 p-1" @click.native="setValues(item)" />
          </v-popover>
          <div @click.prevent="onSubmit(popoverPrice, popoverQuantity, item)">
            <s-switch :id="`switch-${item.id}`" on-value="0" off-value="1" shape="pill" color="primary" v-model="item.deactive_bid" size="xxs" />
          </div>
        </div>
      </template>
    </sp-list-table>
  </sp-list>
</template>

<script>
import BrandSelect from "@/components/brand/BrandSelect.vue";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash-es";
import { getAll, getLocations, getAllCities } from "@/api/vendor/vendor.js";
import { updateAdvanceBid, getBidCounts } from "@/api/vendor/advance-bid.js";
import { remove } from "lodash-es";
import { formatPrice, formatDate } from "@/utilities/common";
import StatusTag from "@/components/StatusTag.vue";
export default {
  data() {
    return {
      defautFilter: {
        search: null,
        city_id: null,
        user_id: null,
        category_id: null,
        brand_id: null,
        grade_name: null,
        deactive_bid: 0,
      },
      filters: {},
      params: {
        limit: 20,
        include: "brand,category,user,city",
      },
      stateId: null,
      allStates: [],
      allCities: [],
      brandOptions: [],
      categoryOptions: [],
      vendorsOptions: [],
      isBrandsLoading: false,
      popoverPrice: null,
      popoverQuantity: null,
      selectedTab: "active",
      switchToggleValue: null,
      activeCounts: 0,
      inActiveCounts: 0,
      columns: [
        {
          name: "user",
          label: "Vendor Name",
        },
        {
          name: "city",
          label: "Location",
          rowClick: false,
          width: "200px",
        },
        "category",
        "brand",
        {
          name: "grade_name",
          label: "Grade",
        },
        {
          name: "quantity",
          label: "Quantity",
        },
        {
          name: "price",
          label: "Price",
        },
        {
          name: "updated_at",
          label: "Date",
          value: (item) => formatDate(item.updated_at),
        },
        {
          name: "action",
          label: "Action",
          rowClick: false,
        },
      ],
    };
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    BrandSelect,
    StatusTag,
  },
  computed: {
    ...mapGetters({
      categoryOption: "categoryOptions",
    }),
    ...mapMutations({
      setBrandId: "order/setBrandId",
    }),
  },
  created() {
    this.filters = cloneDeep(this.defautFilter);
  },
  mounted() {
    this.modifyCategoryOption();
    this.getAllVendors();
    this.getStates();
    this.getCities(null);
    this.getCounts();
  },
  watch: {
    stateId(value) {
      this.getCities(value);
    },
    filters: {
      deep: true,
      handler() {
        console.log("from counts");
        this.getCounts();
      },
    },
  },
  methods: {
    formatPrice,
    formatDate,

    getAllVendors() {
      getAll({ "filter[role]": "vendor" }).then((res) => {
        this.vendorsOptions = res.data;
      });
    },

    modifyCategoryOption() {
      this.categoryOption.map((res) => {
        this.categoryOptions.push({
          id: res.value,
          name: res.label,
        });
      });
    },
    categorySelected(filters) {
      filters.brand_id = null;
    },

    getStates() {
      const params = {
        limit: -1,
        sort: {
          by: "name",
          order: "asc",
        },
      };
      this.filters.city_id = null;
      getLocations(params)
        .then((res) => {
          this.allStates = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },

    getCities(value) {
      const params = {
        filter: {
          state_id: value,
        },
        limit: -1,
        sort: {
          by: "name",
          order: "asc",
        },
      };
      getAllCities(params)
        .then((res) => {
          this.allCities = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },

    handleStateRemove(event) {
      let filterdCities = [];
      let filterdCitiesId = [];
      event.map((res) => {
        filterdCities = remove(this.$refs.cityRef.selectedValue, (city) => {
          if (city.state_id == res) {
            return city.id;
          }
        });
      });
      filterdCities.map((res) => {
        filterdCitiesId.push(res.id);
      });
      this.filters.city_id = filterdCitiesId;
    },

    setValues(item) {
      this.popoverPrice = item.price;
      this.popoverQuantity = item.quantity;
    },

    onSelectTab(tab) {
      this.selectedTab = tab;
      if (tab == "all") {
        this.filters.deactive_bid = null;
      } else if (tab == "active") {
        this.filters.deactive_bid = 0;
        this.columns.push({
          name: "action",
          label: "Action",
          rowClick: false,
        });
      } else {
        this.filters.deactive_bid = 1;
        this.columns.splice(8, 1);
      }
    },

    getCounts() {
      const params = {
        filter: {
          search: this.filters.search,
          city_id: this.filters.city_id,
          user_id: this.filters.user_id,
          category_id: this.filters.category_id,
          brand_id: this.filters.brand_id,
          grade_name: this.filters.grade_name,
        },
      };
      getBidCounts(params).then((res) => {
        this.activeCounts = res[0].active;
        this.inActiveCounts = res[0].inactive;
      });
    },

    onSubmit(price, quantity, item, from = "switch") {
      if (from == "switch") {
        this.$shilp.confirm({
          title: `Are you sure you want to Deactivate ${item.user.name} Advance bid?`,
          message: "Please think twice before changing this status, This action is IRREVERSIBLE.",
          ok: async ({ close }) => {
            try {
              close();
              item.deactive_bid = !item.deactive_bid;
              this.updateBid(item, price, quantity);
            } catch {
              this.$shilp.notify({
                title: "Something went wrong. Try Again.",
                type: "danger",
              });
            } finally {
              this.isChanging = false;
            }
          },
          cancel: () => {
            item.deactive_bid = !item.deactive_bid;
            this.$root.$emit("shilp-modal-close", "shilp-confirm");
          },
        });
      } else {
        this.updateBid(item, price, quantity);
      }
    },

    updateBid(item, price, quantity) {
      const postData = {
        price: price || item.price,
        quantity: quantity || item.quantity,
        city_id: item.city_id,
        category_id: item.category_id,
        brand_id: item.brand_id,
        product_id: item.product_id,
        deactive_bid: item.deactive_bid,
      };
      updateAdvanceBid(item.id, postData)
        .then(() => {
          if (this.$refs[`advance-bid-${item.id}`]) {
            this.$refs[`advance-bid-${item.id}`].hide();
          }
          this.$refs.list.refresh();
          this.getCounts();
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.location-chip {
  background-color: #eceff1;
  color: black;
}
</style>