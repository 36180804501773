<template>
  <v-popover ref="popover" popoverBaseClass="tooltip popover notification-popover" popoverWrapperClass=" w-[700px] h-[500px] rounded-lg overflow-y-scroll" trigger="click" placement="left">
    <template #popover>
      <div v-if="notifications.length">
        <div class="bg-white p-2 sticky top-0">
          <p class="text-md font-semibold">To view all notifications click on the below link</p>
          <a @click="redirectToList('notification-list')" class="cursor-pointer mr-2 text-primary-400 underline text-base">Buyer</a>|<a
            @click="redirectToList('vendor-notification-list')"
            class="cursor-pointer ml-2 text-primary-400 underline text-base"
            >Vendor</a
          >
        </div>
        <Card
          v-for="notification in notifications"
          :key="notification.id"
          :title="notification.title"
          :company="notification.company"
          :description="notification.description"
          :dateTime="notification.created_at"
          :id="notification.notifiable_id"
          class="my-2"
          @click.native="redirect(notification)"
        />
      </div>
      <p v-else class="text-md text-gray-500">No Notifications</p>
    </template>
    <NotificationBell :notifications="notificationCount" />
  </v-popover>
</template>

<script>
import { getAll, getDetail as getNotificationDetail } from "@/api/notifications";
import bus from "@/bus";
import { getNotificationRoute } from "@/utilities/common";
import { mapMutations } from "vuex";
export default {
  params: {
    limit: 10,
    read_at: 0,
    sort_by: "created_at",
    sort_order: "desc",
    // include: "company,order,user,vendorOrder",
  },
  components: {
    Card: require("@/components/notifications/Card").default,
    NotificationBell: require("@/components/notifications/Bell.vue").default,
  },
  data() {
    return {
      notifications: [],
      notificationCount: {}
    };
  },
  created() {
    this.get();

    bus.$on("notification-arrived", () => {
      this.get();
    });

    bus.$on("notification-clicked", () => {
      this.get();
    });
  },
  methods: {
    ...mapMutations({
      setCompanyFilterId: "setCompanyFilterId",
      // setStoreAppInstance: "storeApp/setStoreAppInstance",
    }),
    get() {
      getAll(this.$options.params).then((res) => {
        this.notifications = res.data;
        this.notificationCount = res.meta
      });
    },
    redirect(notification) {
      this.markAsRead(notification);
      bus.$emit("notification-card-clicked");
      let routeName = null;
      routeName = getNotificationRoute(notification.notifiable_type);

      if (routeName == "company-profile") {
        this.setCompanyFilterId(notification.notifiable_id);
        this.$router.push({ name: routeName, params: { id: notification.notifiable_id, status: "incompleteOrPending" } });
      } else if (routeName === "vendor-bids-assign") {
        this.$router.push({ name: routeName, params: { id: notification.notification_detail.vendor_order_id, bidId: notification.notifiable_id }, replace: true });
      } else {
        this.$router.push({ name: routeName, params: { id: notification.notifiable_id }, replace: true });
      }
    },
    redirectToList(routeName) {
      this.$router.push({ name: routeName });
      this.$refs.popover.hide();
    },
    markAsRead(notification) {
      getNotificationDetail(notification.id).then(() => {
        this.get();
      });
    },
  },
};
</script>

<style lang="scss">
.tooltip.notification-popover {
  max-width: max-content;
}
</style>
