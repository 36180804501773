<template>
  <div>
    <ItemForm width="100%" :get="getData" ref="inquiry" :fields="fields" :notify="false" :save="changeStatus" :item="id" title="Inquiry" :form-state.sync="formState" :footer="false">
      <template #default="{ form, data, saveItem, saving }">
        <!-- <s-field label="Date & Time">
            <s-textbox :value="formatDate(form.created_at)" :disabled="true" />
          </s-field> -->
        <div class="card-wrap info-wrapper">
          <div>
            <label class="field__label block"> Date & Time </label>
            <span>{{ formatDate(form.created_at) }}</span>
          </div>

          <div>
            <label class="field__label block"> Status </label>
            <div class="d-flex">
              <Tag :label="data.inquiryStatus ? data.inquiryStatus.name : 'Open'" />
              <v-popover trigger="click" v-if="isInquiryUpdateGranted && data.inquiryStatus.name !== 'Closed'">
                <template #popover>
                  <v-select :options="inquiryStatusOptions" placeholder="Select" :reduce="(item) => item.id" class="w-60" v-model="form.inquiry_status_id" :clearable="false" />
                  <s-field-validate
                    rules="required"
                    v-slot="{ invalid }"
                    label="Additional Comments"
                    class="max-w-sm my-2 ml-1"
                    v-if="!($options.getInquiryTypeByName('web_inquiry').value == data.inquiry_type)"
                  >
                    <div>
                      <s-textarea v-model="inquiryCommentsValue" :rows="4" />
                      <SButton color="primary" label="Submit" :disabled="invalid" class="mt-2" @click.native="saveItem" :loader="saving" />
                    </div>
                  </s-field-validate>

                  <SButton
                    color="primary"
                    label="Submit"
                    v-if="$options.getInquiryTypeByName('web_inquiry').value == data.inquiry_type"
                    class="mt-2"
                    @click.native="updateInquiry"
                    :loader="isInquiryUpdating"
                  />
                </template>
                <s-button title="Change Inquiry Status" key="product-inquiry-status-modal-btn" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </div>

          <div>
            <label class="field__label block"> Type </label>
            <Tag :label="getInquiryType(form.inquiry_type).label" />
          </div>
        </div>

        <div class="card-wrap info-wrapper">
          <div>
            <label class="field__label block">Company Name</label>
            <span>{{ data.company ? data.company.name : "-" }}</span>
          </div>

          <div>
            <label class="field__label block">Company Number</label>
            <span v-if="data.company">{{ data.company.mobile_no }}</span>
            <span v-else-if="data.mobile_no">{{ data.mobile_no }}</span>
            <span v-else> - </span>
          </div>

          <div v-if="data.project">
            <label class="field__label block">Project Name</label>
            <span>{{ data.project.name }}</span>
          </div>

          <div>
            <label class="field__label block">Alternate Number</label>
            <span>{{ form.mobile_no }}</span>
          </div>
        </div>

        <div class="card-wrap info-wrapper">
          <div>
            <label class="field__label block">Category</label>
            <span v-if="form.category">{{ form.category }}</span>
            <span v-else>-</span>
          </div>

          <div>
            <label class="field__label block">Brand</label>
            <span v-if="form.brand">{{ form.brand }}</span>
            <span v-else>-</span>
          </div>

          <div>
            <label class="field__label block">Grade</label>
            <span v-if="form.grade">{{ form.grade }}</span>
            <span v-else>-</span>
          </div>

          <div>
            <label class="field__label block">Size Packing</label>
            <span v-if="form.size_packing">{{ form.size_packing }}</span>
            <span v-else>-</span>
          </div>

          <div>
            <label class="field__label block">Quantity</label>
            <span v-if="form.qty">{{ form.qty }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <s-field label="Additional Requirement" class="max-w-sm my-2 ml-1 card-wrap" v-if="form.additional_requirement">
          <s-textarea :value="form.additional_requirement" :disabled="true" :rows="4" />
        </s-field>
        <s-field label="Attachments" class="max-w-sm my-2 ml-1 card-wrap" v-if="form.attachment">
          <VueFileAgentWrapper description="Attachments" :readonly="true" :linkable="true" :value="form.attachment" :isDeletable="false" />
        </s-field>

        <Timeline :comments="form.inquiryComments" v-if="form.inquiryComments && form.inquiryComments.length" />
      </template>
    </ItemForm>
  </div>
</template>

<script>
import { createInquiryComments, get, update } from "@/api/inquiry";
import { formatDate } from "@/utilities/common";
import { getInquiryType, getInquiryTypeByName } from "@/data";
import { mapGetters } from "vuex";
import roleMixin from "@/mixins/role";
export default {
  mixins: [roleMixin],
  getInquiryTypeByName,
  props: ["id"],
  params: {
    include: "company,product,inquiryStatus,project,inquiryComments",
    read_status: 1,
  },
  data() {
    return {
      inquiryCommentsValue: null,
      isInquiryUpdating: false,
      columns: [
        "comments",
        // {
        //   name: "created_at",
        //   label: "Date & Time",
        //   value: (item) => formatDate(item.created_at),
        // },
      ],
      fields: [
        "created_at",
        "company_id",
        "company_name",
        "category",
        "brand",
        "grade",
        "size_packing",
        "additional_requirement",
        "mobile_no",
        "attachment",
        "inquiry_type",
        "inquiry_status_id",
        "inquiryComments",
        "qty",
        "mobile_no",
      ],
      formState: null,
    };
  },
  computed: {
    ...mapGetters(["inquiryStatusOptions"]),
  },
  components: {
    VueFileAgentWrapper: require("@/components/VueFileAgentWrapper").default,
    Timeline: require("@/components/inquiry/timeline/Index.vue").default,
  },
  created() {
    this.getData();
  },
  watch: {
    id() {
      // This watch is used to dynamically update detail when notification is clicked.
      this.$refs.inquiry.refresh();
    },
  },
  methods: {
    formatDate,
    getInquiryType,
    getData() {
      return get(this.id, this.$options.params).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.company ? res.company.name : "");
        return res;
      });
    },
    changeStatus() {
      const body = {
        description: this.inquiryCommentsValue,
        inquiry_id: this.id,
        company_id: this.formState.company_id,
      };
      const inquiryCommentsPromise = createInquiryComments(body);
      const inquiryPromise = update(this.id, { inquiry_status_id: this.formState.inquiry_status_id });

      return Promise.all([inquiryPromise, inquiryCommentsPromise]).then(() => {
        // this.$router.push({ name: "inquiry-list" });

        this.afterSubmit();
      });
    },
    updateInquiry() {
      // This method is for handling website inquiry flow
      this.isInquiryUpdating = true;
      update(this.id, { inquiry_status_id: this.formState.inquiry_status_id })
        .then(() => this.afterSubmit())
        .finally(() => {
          this.isInquiryUpdating = false;
        });
    },
    afterSubmit() {
      this.$router.push({ name: "inquiries" });
      this.$shilp.notify({
        title: "Inquiry updated.",
        type: "success",
      });
    },
    // showResolveBtn(id) {
    //   if (this.inquiryStatusOptions.find((item) => item.name == "Resolved").id == id) return false;
    //   else return true;
    // },
  },
};
</script>

<style lang="scss" scoped>
.field-block:disabled {
  background: #fff;
}
.d-flex {
  @apply flex gap-x-2;
}
.info-wrapper {
  // @apply grid grid-cols-2 gap-8;
  @apply flex flex-wrap gap-x-10;

  label {
    @apply text-gray-400 block;
  }

  span {
    @apply text-lg;
  }
}

::v-deep .v-select {
  ul {
    position: relative !important;
  }
}
</style>
