import $axios from "@/plugins/axios";

export const getCategories = (params = null) => {
  return $axios.get(`categories`, { params: params }).then((res) => {
    return res.data;
  });
};

export const allCategoriesCount = () => {
  return $axios.get("categories/count/all").then((res) => {
    return res.data;
  });
};

export const categoryWiseSales = (params = null) => {
  return $axios.get("top/categories/sales", { params }).then((res) => res.data);
};

export const categoryWiseSalesTotalAmount = (params = null) => {
  return $axios.get("categoryWise/sales/amountTotal", { params }).then((res) => res.data);
};

export const getDetail = (id, params = null) => {
  return $axios.get(`categories/${id}`, { params: params }).then((res) => {
    return res.data;
  });
};

export const create = (data) => {
  return $axios.post("categories", data).then((res) => {
    return res.data;
  });
};

export const update = (id, data) => {
  return $axios.put(`categories/${id}`, data).then((res) => {
    return res.data;
  });
};