<template>
  <div v-shilp-loader.overlay="isLoading">
    <div class="flex flex--fit gap-2 flex--middle">
      <div class="filter-card-wrapper" @click="onSelected(kycStatus.inreview.value)" :class="{ active: isActive(kycStatus.inreview.value) }">
        <s-button icon="IconMdiReview" theme="muted" color="primary" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold">{{ inreviewCount }}</p>
          <span class="text-base">In Review</span>
        </div>
      </div>

      <div class="filter-card-wrapper" @click="onSelected('incompleteOrPending')" :class="{ active: isActive('incompleteOrPending') }">
        <s-button icon="IconMdiPending" theme="muted" color="warning" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold">{{ incompleteCount + pendingCount }}</p>
          <span class="text-base">Under Process</span>
        </div>
      </div>

      <div class="filter-card-wrapper" @click="onSelected(kycStatus.reject.value)" :class="{ active: isActive(kycStatus.reject.value) }">
        <s-button icon="IconMdiCancel" theme="muted" color="danger" shape="circle" size="lg" />
        <div>
          <p class="text-lg font-bold">{{ rejectedCount }}</p>
          <span class="text-base">Rejected</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kycStatus from "@/data/kycStatus";
import { getCount } from "@/api/company";
import bus from "@/bus";

export default {
  props: ["count", "status"],
  data() {
    return {
  kycStatus,
      active: "",
      // approvedCount: 0,
      incompleteCount: 0,
      inreviewCount: 0,
      pendingCount: 0,
      rejectedCount: 0,
      isLoading: true,
    };
  },

  created() {
    bus.$on("kyc-status-changed", () => {
     this.onSelected(this.kycStatus.inreview.value);
    });
    bus.$on("new-registration", () => {
     this.onSelected(this.kycStatus.inreview.value);
    });
    if (this.status) this.active = this.status;
    this.getKycCount();
  },
  methods: {
    getKycCount() {
      this.isLoading = true;
      getCount().then((res) => {
        // this.approvedCount = res.approve;
        this.incompleteCount = res.incomplete;
        this.inreviewCount = res.inreview;
        this.pendingCount = res.pending;
        this.rejectedCount = res.reject;

        this.isLoading = false;
      });
    },
    onSelected(status) {
      // this.active = status;

      if (status) {
        this.active = status;
        this.getKycCount();
      }
      this.$router.push({ name: "company-listing", params: { status: status } });
    },
    isActive(status) {
      if (this.active == status) return true;
      else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-card-wrapper {
  @apply bg-white flex items-center justify-center gap-4 p-3 rounded-md border-2 hover:drop-shadow-lg transition-all duration-300 cursor-pointer;
  &.active {
    @apply bg-success-200;
  }
}
</style>
