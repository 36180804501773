import $axios from "@/plugins/axios";

export const addLocation = (data, params = null) => {
    return $axios.post("brand-locations", data, { params: params }).then((res) => {
        return res.data;
    });
};

export const sortingBrand = (data, parms = null) => {
    return $axios.post("brands/sorting", data, { params: parms }).then((res) => {
        return res.data
    })
}

export const get = (params = null) => {
    return $axios.get(`states/brands`, { params: params }).then((res) => {
        return res.data;
    });
};

export const remove = (data) => {
    return $axios.delete('brand-locations', { data }).then((res) => {
        return res.data;
    });
};

export const locationStatus = (data) => {
    return $axios.put( 'brand-locations', data).then((res) => {
        return res.data
    })
}