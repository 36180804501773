<template>
  <div v-if="!isLoading && orderInfo">
    <div class="flex flex--fit rounded overflow-hidden bg-white p-2 w-full">
      <p class="font-medium mr-2">Vendor Name</p>
      <p class="font-medium text-gray-500" v-if="orderInfo.user">{{ orderInfo.user.name }}</p>
    </div>
    <div class="rounded overflow-hidden bg-white p-2 w-full mt-1 h-[133px]">
      <div class="flex flex--fit flex--nowrap mb-2">
        <div class="w-3/5">
          <p class="font-medium mb-1 mr-2">Buyer Name</p>
          <p class="text-gray-400" v-if="orderInfo.buyerName">{{ orderInfo.buyerName[0] }}</p>
        </div>
        <div class="2/5">
          <p class="font-medium mb-1 mr-2">Project Name</p>
          <p class="text-gray-400">{{ orderInfo.project_name }}</p>
        </div>
      </div>
      <div>
        <div class="flex flex--fit items-center">
          <p class="font-medium mb-1 mr-5">Delivery Address</p>
          <a class="flex flex--fit items-center border border-gray-200 rounded-full px-1 cursor-pointer" v-if="orderInfo.latitude && orderInfo.longitude" @click="redirectToGoogleMaps">
            <s-icon name="IconGoogleMapMarker" class="mr-px" /> 
            <p class="leading-loose font-medium text-gray-400">See on map</p>
          </a>
        </div>
        <p class="text-gray-400 mt-1">{{ orderInfo.delivery_address }}</p>
        <p></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["orderInfo", "isLoading"],
  methods: {
    redirectToGoogleMaps() {
      window.open(`http://maps.google.com?q=${this.orderInfo.latitude},${this.orderInfo.longitude}`, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .button__label {
  font-size: 8px;
  margin-left: 0px;
}
</style>