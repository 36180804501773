<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add New Brand" icon="IconIcRoundPlus" :to="{ name: 'brand-upsert', params: { id: '+' } }" />
      </div>
    </portal>

    <sp-list
      ref="list"
      pagination-mode="infinite"
      :params="$options.params"
      :perPage="$options.params.limit"
      :filters="filters"
      v-infinite-scroll="loadMoreData"
      :attrs="columns"
      endpoint="brands"
      :actions="['refresh', 'settings']"
    >
      <template #header="{ refresh }">
        <div></div>
        <div class="filter-section">
          <SearchFilter @search="filters.search = $event" />
          <s-button-group>
            <!-- <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$refs.list.toggleSidebar('filters')" /> -->

            <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
          </s-button-group>
        </div>
      </template>
      <template #empty>
        <list-empty />
      </template>
      <sp-list-table @rowClick="$router.push({ name: 'brand-upsert', params: { id: $event.id } })">
        <template #logo="{ item }">
          <brand-logo :url="item.logo.url" v-if="item.logo && item.logo.url" />
        </template>
        <template #name="{ item }">
          <span class="capitalize">{{ item.name }}</span>
        </template>
        <template #category="{ item }">
        <div class="flex gap-2">
          <div v-for="(categories, index) in item.categories" :key="index">
              <StatusTag class="category-chip" :label="categories.name" />
           </div>
        </div>
        </template>
        <template #tag="{ item }">
          <Tag :label="item.tag" v-if="item.tag" />
        </template>

        <template #actions="{ item }">
          <s-button v-if="isBrandDeleteGranted" icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" @click.native="deleteBrand(item.id, item.name)" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { remove } from "@/api/brand";
import tableMixin from "@/mixins/table";
import { getBrandOptions } from "@/api/common";
import { mapActions } from "vuex";
import roleMixin from "@/mixins/role";

export default {
  params: {
    limit: 25,
    include: "categories",
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    BrandLogo: require("@/components/BrandLogo.vue").default,
    Tag: require("@/components/Tag.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    StatusTag: require("@/components/StatusTag.vue").default
  },
  data() {
    return {
      filters: {
        search: null,
      },
      columns: [
        { name: "logo", label: "Logo" },
        { name: "name", label: "Name" },
        { name: "category", label: "Category", width: '300px'},
        { name: "tag", label: "Tag" },
        { name: "actions", label: "Actions", rowClick: false, fix: true },
      ],
    };
  },
  mixins: [tableMixin, roleMixin],

  methods: {
    ...mapActions({
      setBrandOptions: "setBrandOptions",
    }),
    async deleteBrand(id, brandName) {
      try {
        const adminSelectedDelete = confirm(`Are you sure to delete ${brandName} brand ?`);
        if (adminSelectedDelete) {
          await remove(id);
          this.$shilp.notify({
            title: "Brand Deleted Successfully",
            type: "success",
          });

          const brandOptions = await getBrandOptions();
          this.setBrandOptions(brandOptions);

          this.$refs.list.refresh();
        }
      } catch ({ data }) {
        this.$shilp.notify({
          title: data.message,
          type: "danger",
        });
      }
    },
  },
};
</script>

<style>
.filter-section {
  @apply flex gap-4 items-center;
}
.category-chip {
  background-color: #eceff1;
  color: black;
}
</style>
