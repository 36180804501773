<template>
  <div class="flex flex--fit flex--nowrap" v-if="!isLoading && orderInfo">
    <div class="rounded overflow-hidden bg-white p-2 mr-2 w-4/12">
      <p class="font-medium">Buyer Contact Persons</p>
      <table class="mt-2 h-[200px]" v-if="orderInfo.contact_person.length != 0">
        <tr class="border-t border-b">
          <th class="text-left w-1/2 p-1">Name</th>
          <th class="text-left w-1/2 p-1">Designation</th>
          <th class="text-left w-1/2 p-1">Mobile No</th>
        </tr>
        <tr v-for="contact in orderInfo.contact_person" :key="contact.id">
          <td class="text-gray-500">{{ contact.name }}</td>
          <td class="text-gray-500">{{ contact.designation[0].display_name }}</td>
          <td class="text-gray-500">{{ contact.mobile_no }}</td>
        </tr>
      </table>
      <template v-else>
        <p class="no-data-found">Contact Persons Not Found</p>
      </template>
    </div>

    <div class="rounded overflow-hidden bg-white p-2 mr-2 w-4/12">
      <p class="mb-1 font-medium">Driver Info</p>
      <table class="mt-2 h-[200px]" v-if="orderInfo.vendorOrderDrivers.length != 0">
        <tr class="border-t border-b">
          <th class="text-left w-1/2 p-1">Driver Name</th>
          <th class="text-left w-1/2 p-1">Driver Number</th>
          <th class="text-left w-1/2 p-1">Truck Number</th>
        </tr>
        <tr v-for="driver in orderInfo.vendorOrderDrivers" :key="driver.id">
          <td class="text-gray-500">{{ driver.name }}</td>
          <td class="text-gray-500">{{ driver.mobile_no }}</td>
          <td class="text-gray-500">{{ driver.truck_no }}</td>
        </tr>
      </table>
      <template v-else>
        <p class="no-data-found">Driver Info Not Found</p>
      </template>
    </div>

    <div class="rounded overflow-hidden bg-white p-2 mr-2 w-4/12 invoice">
      <div class="flex flex--fit justify-between">
        <p class="font-medium mb-2">Invoices</p>
        <div class="flex flex--fit">
          <a class="flex flex--fit mr-3 mt-1 text-base text-gray-500 cursor-pointer" @click="redirectToWhatsapp(orderInfo)"><s-icon name="IconMdiShareVariantOutline" class="mt-1 mr-1" />Share</a>
          <s-button color="primary" label="Add" class="button--sm" @click.native="addInvoice" />
        </div>
      </div>
      <template v-if="orderInfo.invoices">
        <div v-for="invoice in orderInfo.invoices" :key="invoice.id">
          <div class="flex flex--fit justify-between">
            <div>
              <input style="max-width: 20px; height: 20px" type="checkbox" @click="selectedInvoices(invoice)" class="mr-2" :id="invoice.id" />
              <s-button
                :key="`attachment-${invoice.id}`"
                class="attachment"
                icon="IconMdiFileDocumentMultipleOutline"
                size="sm"
                theme="muted"
                :label="invoice.filename"
                @click.native="openAttachment(invoice)"
              />
            </div>
            <a @click="deleteInvoice(invoice)" class="cursor-pointer"><s-icon name="IconMdiTrashCanOutline" class="text-base h-8 text-gray-500" /></a>
          </div>

          <hr />
        </div>
      </template>
      <template v-else>
        <p class="no-data-found">No Invoices Found</p>
      </template>
      <AddInvoice @refreshInvoice="$emit('refresh')" />
    </div>
  </div>
</template>

<script>
import AddInvoice from "./AddInvoice.vue";
import $axios from "@/plugins/axios";
import { remove } from "lodash-es";
export default {
  props: ["orderInfo", "isLoading"],
  components: {
    AddInvoice,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_STORE_REST_API,
      selectedInvoice: [],
    };
  },
  methods: {
    openAttachment(invoice) {
      window.open(invoice.url, "_blank");
    },
    addInvoice() {
      this.$root.$emit("shilp-modal-open", {
        id: "add-invoice-modal",
        scope: {},
      });
    },
    redirectToWhatsapp(orderInfo) {
      let invoices = [];
      if (this.selectedInvoice && this.selectedInvoice.length) {
        for (let invoice of this.selectedInvoice) {
          invoices.push(invoice.url);
        }
        const formattedInvoices = invoices.join(" %0A ");
        const textMsg = `Hello ${orderInfo.user.name} your order invoices: %0A ${formattedInvoices}`;
        window.open(`https://wa.me/${orderInfo.user.mobile_no}?text=${textMsg}`, "_blank");
      } else {
        this.$shilp.notify({
          title: "Please select invoice to share",
          type: "danger",
        });
      }
    },
    selectedInvoices(invoice) {
      const isAlreadyAdded = this.selectedInvoice.find((res) => {
        return invoice.id == res.id;
      });
      if (isAlreadyAdded) {
        this.selectedInvoice = remove(this.selectedInvoice, (res) => {
          return invoice.id != res.id;
        });
      } else {
        this.selectedInvoice.push(invoice);
      }
    },
    deleteInvoice(invoice) {
      this.$shilp.confirm({
        title: "Delete confirmation",
        message: `Are you sure you want to delete "${invoice.filename}" Invoice`,
        okLabel: "Yes",
        cancelColor: "grey",
        ok: ({ close }) => {
          $axios
            .delete(`${this.baseURL}/medias/${invoice.id}`)
            .then(() => {
              close();
              this.$emit("refresh");
              this.$shilp.notify({
                title: "Invoice deleted.",
                type: "success",
              });
            })
            .catch((e) => {
              close();
              console.log(e);
            });
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.divide-dotted {
  border-style: dashed;
}
table {
  overflow-y: scroll;
  height: 150px;
  display: block;
  th {
    font-size: smaller;
  }
}

::v-deep .invoice {
  .attachment {
    .button__icon,
    .button__label {
      @apply text-gray-500;
    }
  }
}

.no-data-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
</style>
 