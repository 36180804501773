<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add New Category" icon="IconIcRoundPlus" :to="{ name: 'manage-category', params: { id: '+' } }" />
      </div>
    </portal>

    <sp-list ref="list" :params="$options.params" :perPage="$options.params.limit" :filters="filters" :attrs="columns" endpoint="categories" :actions="['refresh', 'settings']">
      <template #header="{ refresh }">
        <div></div>
        <div class="filter-section">
          <SearchFilter @search="filters.search = $event" />
          <s-button-group>
            <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
          </s-button-group>
        </div>
      </template>
      <template #empty>
        <list-empty />
      </template>
      <sp-list-table @rowClick="$router.push({ name: 'manage-category', params: { id: $event.id } })">
        <template #name="{ item }">
          <span class="capitalize">{{ item.name }}</span>
        </template>
        <template #attribute="{ item }">
          <div v-if="item.attribute.length">
            <span v-for="(option, index) of item.attribute[0].attributeOption" :key="index">
              <StatusTag :label="option.name" class="category-chip m-1" />
            </span>
          </div>
          <span v-else>-</span>
        </template>
        <template #actions="{ item }">
          <div class="flex items-center gap-2">
            <div :key="`state-modal-${item.id}`" @click.prevent.stop="toggleStatus(item)">
              <s-switch
                :id="`state-status-switch-${item.id}`"
                :name="`state-status-switch-${item.id}`"
                :key="`state-status-switch-${item.id}`"
                :value="item.id == selectedStatus.id ? selectedStatus.status : '' || item.status"
                on-value="active"
                off-value="inactive"
                shape="pill"
                color="primary"
                size="xs"
              />
            </div>
          </div>
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { update } from "@/api/category";
export default {
  params: {
    limit: 25,
  },
  components: {
    ListEmpty: require("@/components/ListEmpty.vue").default,
    SearchFilter: require("@/components/SearchFilter.vue").default,
    StatusTag: require("@/components/StatusTag.vue").default,
  },
  data() {
    return {
      filters: {
        search: null,
      },
      selectedStatus: {},
      columns: [
        { name: "name", label: "Name" },
        { name: "attribute", label: "Grade" },
        { name: "actions", label: "Actions", rowClick: false, fix: true },
      ],
    };
  },

  methods: {
    toggleStatus(item) {
      this.$shilp.confirm({
        title: "Confirm",
        description: "Are you sure you want to change status?",
        ok: async ({ close }) => {
          try {
            close();
            item.status = item.status == "active" ? "inactive" : "active";
            this.selectedStatus = item;
            await update(item.id, { status: item.status, name: item.name });
          } catch {
            this.$shilp.notify({
              title: "Something went wrong. Try Again.",
              type: "danger",
            });
          }
        },
        cancel: () => {
          this.$root.$emit("shilp-modal-close", "shilp-confirm");
        },
      });
    },
  },
};
</script>

<style>
.filter-section {
  @apply flex gap-4 items-center;
}
.category-chip {
  background-color: #eceff1;
  color: black;
}
</style>