export default [
    {
        path: "locations",
        name: "locations",
        component: require("@/views/private/master/locations/Index").default,
        meta: {
            label: "Locations",
            breadcrumbs: ["dashboard", "locations"],
        },
    },
];
