<template>
  <div>
    <!-- Label -->

    <div class="card-container">
      <div class="card-section">
        <div>
          <h4 class="detail-content-label">
            Order ID <span>#{{ order.order_no }}</span>
          </h4>
          <h4>{{ formatDate(dateTime) }}</h4>
        </div>

        <div>
          <!-- expectedDeliveryDate -->
          <h4 class="detail-content-label">Expected Delivery Date</h4>
          <div class="font-bold text-md flex gap-2 items-center flex--fit">
            {{ moment(expectedDeliveryDate * 1000).format("MMM D YYYY") }}
            <v-popover v-if="showUpdateDeliveryDate" trigger="click" :autoHide="false" popoverBaseClass="tooltip popover expectedDeliveryDatePopover">
              <s-button title="Update Delivery Date" :key="`update-delivery-date-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              <template #popover>
                <div class="text-right" v-close-popover>
                  <s-button icon="IconIcRoundClose" color="grey" shape="circle" theme="muted" size="xs" />
                </div>
                <s-field-validate label="Select Date" rules="required" v-slot="{ invalid }">
                  <div>
                    <CustomDatePicker v-model="updatedDeliveryDate" :range="false" pastDateDisabled :futureDateDisabled="false" style="display: block" />
                    <s-button class="mt-2" label="Submit" color="primary" :disabled="invalid" @click.native="onDateSelect" :loader="isDeliveryDateUpdating" />
                  </div>
                </s-field-validate>
              </template>
            </v-popover>
          </div>
        </div>

        <div>
          <!-- Category -->
          <h4 class="detail-content-label">Category</h4>
          <h4 class="font-bold">{{ category }}</h4>
        </div>

        <div class="flex">
          <!-- Brand -->
          <!-- <h4 class="detail-content-label">{{ brand.name }}</h4> -->
          <BrandLogo :url="brand.logo.url" size="xl" :name="brand.name"/>
          <span class="border-r border-gray-200 mx-4"></span>
          <div>
            <h4 class="detail-content-label">Share via</h4>
            <s-button icon="IconMdiWhatsapp" label="WhatsApp" class="text-white button--sm" color="success" @click.native="redirectTowhatsapp(order)" />
          </div>
        </div>
      </div>
    </div>

    <div class="card-container">
      <table>
        <!-- <div class="order-detail__header"></div> -->

        <tr>
          <td class="detail-item">
            <h4 class="detail-content-label">Order Status</h4>

            <div class="order-status-wrap">
              <order-status-tag :name="orderStatus.name" :label="orderStatus.display_name" />
              <v-popover trigger="click" v-if="showOrderStatusUpdateField">
                <template #popover>
                  <UpdateOrderStatus :cancellationAllowed="isOrderCancellationAllowed" :orderId="id" :status="orderStatus.id" @order-status-changed="$emit('order-status-changed')" />
                </template>
                <s-button title="Change Status" :key="`product-status-modal-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </td>

          <td class="detail-item">
            <h4 class="detail-content-label">Payment Status</h4>
            <div class="order-status-wrap">
              <!-- Payment Status -->

              <payment-status-tag :label="paymentStatus.display_name" :name="paymentStatus.name" />

              <v-popover trigger="click" popoverBaseClass="tooltip popover paymentStatusPopover">
                <template #popover>
                  <UpdatePaymentStatus
                    :orderId="id"
                    :totalAmount="order.total"
                    :receivedAmount="order.receive_amount"
                    :status="paymentStatus.id"
                    @payment-status-changed="$emit('payment-status-changed')"
                  />
                </template>
                <s-button title="Change Status" :key="`product-status-modal-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </td>

          <td class="detail-item">
            <!--  Payment terms-->

            <h4 class="detail-content-label">Payment Terms</h4>
            <h4 class="font-bold">{{ paymentTerms }}</h4>
          </td>

          <td class="apply-discount">
            <!-- <v-popover> -->
            <!-- <template #popover> -->

            <s-button-group theme="tab" color="secondary" class="mb-3">
              <s-button @click.native="isDiscountOrPremiumActive = 'discount'" label="Discount" :active="isDiscountOrPremiumActive === 'discount'" />
              <s-button @click.native="isDiscountOrPremiumActive = 'premium'" label="Premium" :active="isDiscountOrPremiumActive === 'premium'" />
            </s-button-group>

            <Discount
              v-if="isDiscountOrPremiumActive === 'discount'"
              :orderStatus="orderStatus"
              :orderId="id"
              @discountApplied="$emit('discountApplied')"
              class="flex flex--fit"
              :disabled="!showDiscountUpdateField"
            />
            <Premium
              v-if="isDiscountOrPremiumActive === 'premium'"
              :orderStatus="orderStatus"
              :orderId="id"
              @premiumApplied="$emit('premiumApplied')"
              class="flex flex--fit"
              :disabled="!showDiscountUpdateField"
            />

            <s-button
              label="Reset Price"
              v-tooltip="'Bring back original price if discount or premium applied due to some mistake'"
              @click.native="revertBack"
              icon="IconMdiRestart"
              color="success"
              :loader="isReverting"
              class="mt-2"
            />

            <!-- </template> -->
            <!-- <s-button label="Enter Discount" color="secondary" /> -->
            <!-- </v-popover> -->
          </td>
        </tr>

        <tr>
          <td class="detail-item">
            <!-- Loading -->
            <h4 class="detail-content-label">Loading</h4>
            <div class="loading-wrap">
              <Tag :label="isLoadingIncluded ? 'Included' : 'Excluded'" :color="isLoadingIncluded ? 'green' : 'red'" width="50px" class="mr-2" />
              <v-popover trigger="click" v-if="!isLoadingIncluded && showTransportChargesBtn">
                <template #popover>
                  <ValidationObserver>
                    <template #default="{ invalid }">
                      <s-field-validate class="amount-wrapper" name="Amount" label="Enter Amount" :rules="`required|positiveInteger`">
                        <STextbox type="number" v-model.number="loadingAmount" />
                      </s-field-validate>
                      <SButton color="primary" label="Submit" class="mt-2 pd-2" @click.native="loadingAmountchange" :disabled="invalid" :loader="isLoading" />
                    </template>
                  </ValidationObserver>
                </template>
                <s-button title="Enter Amount" :key="`update-loading-amount-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </td>

          <td class="detail-item">
            <!-- Unloading -->

            <h4 class="detail-content-label">Unloading</h4>
            <div class="unloading-wrap">
              <Tag :label="isUnloadingIncluded ? 'Included' : 'Excluded'" :color="isUnloadingIncluded ? 'green' : 'red'" width="50px" class="mr-2" />
              <v-popover trigger="click" v-if="!isUnloadingIncluded && showTransportChargesBtn">
                <template #popover>
                  <ValidationObserver>
                    <template #default="{ invalid }">
                      <s-field-validate name="Amount" label="Enter Amount" :rules="`required|positiveInteger`">
                        <STextbox type="number" v-model.number="unloadingAmount" />
                      </s-field-validate>
                      <SButton color="primary" label="Submit" class="mt-2" @click.native="unloadingAmountchange" :disabled="invalid" :loader="isLoading" />
                    </template>
                  </ValidationObserver>
                </template>
                <s-button title="Enter Amount" :key="`update-unloading-amount-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </td>

          <td>
            <!--Freight -->

            <h4 class="detail-content-label">Freight</h4>
            <div class="freight-wrap">
              <Tag :label="isFreightIncluded ? 'Included' : 'Excluded'" :color="isFreightIncluded ? 'green' : 'red'" width="50px" class="mr-2" />
              <v-popover trigger="click" v-if="!isFreightIncluded && showTransportChargesBtn">
                <template #popover>
                  <ValidationObserver>
                    <template #default="{ invalid }">
                      <s-field-validate name="Amount" label="Enter Amount" :rules="`required|positiveInteger`">
                        <STextbox type="number" v-model.number="freightAmount" />
                      </s-field-validate>
                      <SButton color="primary" label="Submit" class="mt-2" @click.native="freightAmountchange" :disabled="invalid" :loader="isLoading" />
                    </template>
                  </ValidationObserver>
                </template>
                <s-button title="Enter Amount" :key="`update-freight-amount-btn-${id}`" icon="IconMdiPencilOutline" size="sm" color="grey" theme="muted" shape="circle" />
              </v-popover>
            </div>
          </td>

          <td class="detail-item payment-received">
            <!--  Payment Amount Received-->

            <h4 class="detail-content-label">Payment Received</h4>
            <h4 class="font-bold">{{ formatPrice(receiveAmount) }}</h4>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate, formatPrice, alertApiError } from "@/utilities/common";
import orderStatusData from "@/data/orderStatus";
import paymentStatusData from "@/data/paymentStatus";
import { changeOrderOrPaymentStatus } from "@/api/order";
import moment from "moment";
import Premium from "./Premium.vue";
export default {
  orderStatusData,
  paymentStatusData,
  props: ["dateTime", "expectedDeliveryDate", "paymentTerms", "category", "brand", "paymentStatus", "id", "orderStatus", "receiveAmount", "additionalInfo", "order"],
  components: {
    BrandLogo: require("@/components/BrandLogo").default,
    Tag: require("@/components/Tag").default,
    PaymentStatusTag: require("@/components/order/PaymentStatusTag").default,
    OrderStatusTag: require("@/components/OrderStatusTag.vue").default,
    UpdateOrderStatus: require("@/components/order/UpdateStatus.vue").default,
    UpdatePaymentStatus: require("@/components/order/UpdatePaymentStatus.vue").default,
    Discount: require("@/components/order/Discount.vue").default,
    CustomDatePicker: require("@/components/CustomDatePicker.vue").default,
    Premium,
  },
  data() {
    return {
      isLoading: false,
      loadingAmount: this.order.loading,
      unloadingAmount: this.order.unloading,
      freightAmount: this.order.freight,
      updatedDeliveryDate: null,
      isDeliveryDateUpdating: false,
      isDiscountOrPremiumActive: "discount",
      isReverting: false,
    };
  },

  created() {
    this.updatedDeliveryDate = this.moment(this.expectedDeliveryDate * 1000).format("DD-MM-YYYY");
  },
  computed: {
    isOrderCancellationAllowed() {
      let orderStatusId = [
        this.$options.orderStatusData.partial_shipped,
        this.$options.orderStatusData.shipped,
        this.$options.orderStatusData.partial_delivered,
        this.$options.orderStatusData.delivered,
      ];

      // ORder PRoducts logic
      for (let item of this.order.orderProducts) {
        if (orderStatusId.includes(item.orderProductStatus.id)) return false;
      }

      // Order Level
      if (this.orderStatus.id == this.$options.orderStatusData.cancelled) return false;

      // Checking for payment status paid

      if (this.$options.paymentStatusData.paid === this.paymentStatus.id || this.$options.paymentStatusData.partiallypaid === this.paymentStatus.id) return false;
      return true;
    },
    showDiscountUpdateField() {
      if (
        this.orderStatus.id == this.$options.orderStatusData.cancelled ||
        this.orderStatus.id == this.$options.orderStatusData.delivered ||
        this.$options.paymentStatusData.paid == this.paymentStatus.id
      )
        return false;
      else return true;
    },
    showOrderStatusUpdateField() {
      if (this.orderStatus.id == this.$options.orderStatusData.cancelled || this.orderStatus.id == this.$options.orderStatusData.delivered) return false;
      else return true;
    },

    showUpdateDeliveryDate() {
      if (this.orderStatus.id == this.$options.orderStatusData.cancelled || this.paymentStatus.id == this.$options.paymentStatusData.paid) return false;
      else return true;
    },

    showPaymentStatusUpdateField() {
      if (this.orderStatus.id == this.$options.orderStatusData.cancelled || this.paymentStatus.name == "paid") return false;
      else return true;
    },
    isLoadingIncluded() {
      if (this.order.loading) {
        return false;
      } else {
        return true;
      }
    },
    isUnloadingIncluded() {
      if (this.order.unloading) {
        return false;
      } else {
        return true;
      }
    },
    isFreightIncluded() {
      console.log('freight==>>',this.order)
      if (this.order.freight) {
        return false;
      } else {
        return true;
      }
    },
    showTransportChargesBtn() {
      if (this.$options.orderStatusData.cancelled === this.orderStatus.id || this.$options.orderStatusData.delivered === this.orderStatus.id) {
        return false;
      } else if (this.$options.paymentStatusData.paid === this.paymentStatus.id) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    revertBack() {
      this.isReverting = true;
      changeOrderOrPaymentStatus(this.id, {
        default_price_set: true,
      })
        .then(() => {
          this.$shilp.notify({
            title: "Revert back Success",
            type: "success",
          });
          this.$emit("revertBack");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isReverting = false;
        });
    },
    async loadingAmountchange() {
      this.isLoading = true;
      try {
        let formData = {
          loading: this.loadingAmount,
        };
        await changeOrderOrPaymentStatus(this.id, formData);
        this.$shilp.notify({
          title: "Transport Charges Updated.",
          type: "success",
        });
        this.$emit("transport-charges-updated");
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async unloadingAmountchange() {
      this.isLoading = true;
      try {
        let formData = {
          unloading: this.unloadingAmount,
        };
        await changeOrderOrPaymentStatus(this.id, formData);
        this.$shilp.notify({
          title: "Transport Charges Updated.",
          type: "success",
        });
        this.$emit("transport-charges-updated");
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async freightAmountchange() {
      this.isLoading = true;
      try {
        let formData = {
          freight: this.freightAmount,
        };
        await changeOrderOrPaymentStatus(this.id, formData);
        this.$shilp.notify({
          title: "Transport Charges Updated.",
          type: "success",
        });
        this.$emit("transport-charges-updated");
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },

    onDateSelect() {
      this.isDeliveryDateUpdating = true;
      changeOrderOrPaymentStatus(this.id, {
        expected_delivery_date: this.updatedDeliveryDate,
      })
        .then(() => {
          this.$shilp.notify({
            title: "Delivery Date Updated.",
            type: "success",
          });
          this.$emit("delivery-date-updated");
        })
        .catch((error) => {
          alertApiError(error, this);
        })
        .finally(() => {
          this.isDeliveryDateUpdating = false;
        });
    },
    redirectTowhatsapp(orderInfo) {
      console.log("orderInfo", orderInfo);
      const textMsg = `Hello ${orderInfo.company.name} your order details: %0A Order Id: ${orderInfo.order_no},%0A Category: ${this.category},%0A Brand: ${this.brand.name},%0A Quantity: ${orderInfo.total_quantity} %0A ${process.env.VUE_APP_BASE_URL}/order-detail/${orderInfo.id}`;
      window.open(`https://wa.me/${orderInfo.company.mobile_no}?text=${textMsg}`, "_blank");
    },
    formatDate,
    formatPrice,
    moment,
  },
};
</script>

<style lang="scss" scoped>
.loading-wrap,
.unloading-wrap,
.freight-wrap {
  @apply flex;
}
.card-container {
  @apply rounded-lg shadow-lg p-4 my-4 bg-white;

  &:first-of-type {
    @apply mt-0;
  }
  .card-section {
    @apply text-3xl font-bold flex justify-between items-center;
  }
}

.card-container table {
  @apply w-full;
}
.card-container table tr {
  @apply border-b-2;

  &:last-child {
    @apply border-none;
  }

  td {
    @apply p-4;
  }

  td.apply-discount {
    @apply border-l-2;
  }
}

td.payment-received {
  @apply border-l-2;
}

.order-detail-grid {
  .detail-item {
    @apply border-b py-2;
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  // gap: 16px;

  row-gap: 16px;
}

.detail-content-label {
  @apply text-gray-500 font-normal;
}

.order-status-wrap {
  @apply flex gap-2 items-center;
}
</style>

<style>
.tooltip.popover.paymentStatusPopover,
.tooltip.popover.expectedDeliveryDatePopover {
  z-index: 10 !important;
}
</style>
