<template>
  <div>
    <List :params="params" :columns="columns" actionRoute="vendor-order-details" module="assigned" />
  </div>
</template>

<script>
import List from "../List.vue";
export default {
  data() {
    return {
      columns: [
        {
          name: "order_no",
          label: "ID",
          width: "90px",
        },
        {
          name: "category_id",
          label: "Brand",
        },
        {
          name: "qty",
          label: "Quantity",
        },
        {
          name: "total",
          label: "Amount",
        },
        {
          name: "user",
          label: "Vendor Name",
          width: "115px",
        },
        {
          name: "buyerName",
          label: "Buyer Name",
          width: "115px",
        },
        {
          name: "project_name",
          label: "Project Name",
        },
        {
          name: "vendor_order_status_id",
          label: "Order Status",
        },
        {
          name: "created_at",
          label: "Date & Time",
        },
        {
          name: "updated_at",
          label: "Updated At",
        },
        {
          name: "assign_action",
          label: "Action",
          rowClick: false,
        },
      ],
      params: {
        limit: 25,
        include: "orderStatus, category, brand",
        "sort[order]": "desc",
        "sort[by]": "updated_at",
      },
    };
  },
  components: {
    List,
  },
};
</script>