var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('item-form',{ref:"item",staticClass:"p-4 card-wrap",attrs:{"submitOnEnter":false,"fields":_vm.fields,"redirect":false,"notify":false,"width":"100%","get":_vm.getItem,"item":_vm.id,"save":_vm.saveItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var form = ref.form;
return [_c('div',{staticClass:"md:grid grid-cols-[400px_auto] gap-4"},[_c('div',{staticClass:"flex gap-2"},[_c('s-field-validate',{attrs:{"label":"Name","rules":"required","name":"Name"}},[_c('s-textbox',{attrs:{"placeholder":"Enter Brand Name"},model:{value:(form.name),callback:function ($$v) {_vm.$set(form, "name", $$v)},expression:"form.name"}})],1),_c('s-field-validate',{attrs:{"label":"Expected Delivery Days","rules":"required|positiveNonZeroInteger","name":"expected_delivery_days"}},[_c('s-textbox',{attrs:{"placeholder":"Enter Number of Days","type":"number"},model:{value:(form.expected_delivery_days),callback:function ($$v) {_vm.$set(form, "expected_delivery_days", _vm._n($$v))},expression:"form.expected_delivery_days"}})],1),_c('div',{staticClass:"flex gap-3 w-full"},[_c('s-field-validate',{staticClass:"w-full",attrs:{"label":"Category","rules":"required","name":"Category"}},[_c('v-select',{attrs:{"multiple":true,"close-on-select":false,"options":_vm.categoryOption,"placeholder":"Select category","reduce":function (category) { return category.value; },"label":"label"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
var value = ref.value;
return [_c('label',{staticClass:"flex items-center"},[_c('input',{staticClass:"mr-2",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isCategorySelected(form, value)}}),_c('span',{staticClass:"leading-6"},[_vm._v(_vm._s(label))])])]}}],null,true),model:{value:(form.category_id),callback:function ($$v) {_vm.$set(form, "category_id", $$v)},expression:"form.category_id"}})],1),_c('s-field-validate',{staticClass:"w-full",attrs:{"label":"Tag","name":"Tag"}},[_c('v-select',{attrs:{"options":_vm.$options.options.brandTags,"label":"label","placeholder":"Select","reduce":function (item) { return item.value; }},model:{value:(form.tag),callback:function ($$v) {_vm.$set(form, "tag", $$v)},expression:"form.tag"}})],1)],1),_c('s-field-validate',{attrs:{"label":"Logo","rules":"required","name":"Logo"}},[_c('VueFileAgentWrapper',{attrs:{"linkable":true,"accept":".jpg,.png,.jpeg","description":"Choose any image"},model:{value:(form.logo),callback:function ($$v) {_vm.$set(form, "logo", $$v)},expression:"form.logo"}})],1),_c('s-field',{attrs:{"label":"Loading"}},[_c('BrandOptions',{key:"loading-options",model:{value:(form.loading),callback:function ($$v) {_vm.$set(form, "loading", $$v)},expression:"form.loading"}})],1),_c('s-field',{attrs:{"label":"Unloading"}},[_c('BrandOptions',{key:"unloading-options",model:{value:(form.unloading),callback:function ($$v) {_vm.$set(form, "unloading", $$v)},expression:"form.unloading"}})],1),_c('s-field',{attrs:{"label":"Freight"}},[_c('BrandOptions',{key:"freight-options",model:{value:(form.freight),callback:function ($$v) {_vm.$set(form, "freight", $$v)},expression:"form.freight"}})],1)],1),_c('div',[_c('s-field',{attrs:{"label":"Brand Description"}},[_c('QuillEditor',{model:{value:(form.description),callback:function ($$v) {_vm.$set(form, "description", $$v)},expression:"form.description"}})],1)],1)])]}},{key:"footer",fn:function(ref){
var saving = ref.saving;
var saveItem = ref.saveItem;
return [_c('div',[_c('s-button',{attrs:{"label":"Submit","color":"primary","loader":saving},nativeOn:{"click":function($event){return saveItem()}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }