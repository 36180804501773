<template>
  <div ref="form">
    <Item @locationAdded="locationSubmitted" :categoryDetail="editCategory" />
    <List ref="listComponent" @onEditCategory="edit" />
  </div>
</template>

<script>
import Item from "./Item.vue";
import List from "./List.vue";
export default {
  data() {
    return {
      details: null,
      isLoading: false,
      editCategory: null,
    };
  },
  components: {
    Item,
    List,
  },
  mounted() {},
  methods: {
    locationSubmitted() {
      this.$refs.listComponent.getLocations();
    },
    edit(event) {
      const formElement = this.$refs.form;
      if (formElement) {
        formElement.scrollIntoView({
          top: 0,
          behavior: "smooth",
        });
      }
      this.editCategory = event;
    },
  },
};
</script>