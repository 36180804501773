<template>
  <div>
    <s-field label="Buyer Type" v-shilp-loader.overlay="isLoading" class="field--required">
    <v-select :options="options" :disabled="isDisabled" label="name" placeholder="Select" :clearable="false" :reduce="(item) => item.id" v-model="selected" @option:selected="select" />
  </s-field>
  <p class="text-red-500 mt-2 text-xs" v-if="!selected">Assign Buyer Type to proceed.</p>
  </div>
</template>

<script>
import { get, assignBuyerTypeToCompany } from "@/api/buyerType";
export default {
  props: ["companyId", "value", "isDisabled"],
  data() {
    return {
      isLoading: false,
      options: [],
      selected: null,
    };
  },
  created() {
    if (this.value) this.selected = this.value;
    this.getOptions();
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
  methods: {
    async select(option) {
      this.isLoading = true;
      assignBuyerTypeToCompany(this.companyId, option.id)
        .then(() => {
          this.$shilp.notify({
            title: "Buyer Type Assigned",
            type: "success",
          });
          this.$emit("buyerSelected");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getOptions() {
      this.isLoading = true;
      get({ limit: -1 }).then((res) => {
        this.options = res.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
