<template>
  <div class="tag min-w-max" :class="`tag--${color} tag--${name}`">
    <span class="tag__emoji">{{ emoji }}</span>
    <span class="tag__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    name: { type: String, default: null },
    color: { type: String, default: null },
  },
  computed: {
    emoji() {
      let icon = "";
      switch (this.name) {
        case "confirmed":
          icon = "✅";
          break;
        case "return":
          icon = "🔙";
          break;
        case "pending":
          icon = "⚠️";
          break;
        case "loaded":
        case "intransit":
        case "shipped":
        case "partial_shipped":
          icon = "🚚";
          break;
        case "delivered":
        case "partial_delivered":
          icon = "✅";
          break;
        case "replacement":
          icon = "";
          break;
        case "shortage":
          icon = "🚩";
          break;

        case "cancelled":
          icon = "❌";
          break;
        case "onhold":
          icon = "🚧";
          break;
      }

      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  background: var(--light);
  color: var(--dark);
  @apply h-5 inline-flex p-3 items-center rounded-lg align-top;
}
.tag__emoji {
  @apply text-xs leading-none mr-1;
}

.tag__label {
  @apply font-medium text-sm capitalize;
}

.tag--intransit,
.tag--partial_shipped,
.tag--RFP,
.tag--partial_delivered {
  --light: #fff3e0;
  --dark: #fb8c00;
}

.tag--confirmed,
.tag--delivered,
.tag--shipped {
  --light: #e8f5e9;
  --dark: #388e3c;
}

.tag--loaded,
.tag--replacement {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--return,
.tag--shortage,
.tag--cancelled,
.tag--onhold {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--orange,
.tag--pending {
  --light: #fff3e0;
  --dark: #fb8c00;
}
.tag--amber {
  --light: #fff8e1;
  --dark: #ffa000;
}
.tag--lime {
  --light: #f9fbe7;
  --dark: #afb42b;
}
.tag--green {
  --light: #e8f5e9;
  --dark: #388e3c;
}
.tag--cyan {
  --light: #e0f7fa;
  --dark: #0097a7;
}
.tag--blue {
  --light: #e3f2fd;
  --dark: #1976d2;
}

.tag--indigo {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--purple {
  --light: #f3e5f5;
  --dark: #7b1fa2;
}

.tag--pink {
  --light: #fce4ec;
  --dark: #c2185b;
}

.tag--red {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--brown {
  --light: #efebe9;
  --dark: #6d4c41;
}
</style>
