<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Product" icon="IconIcOutlineInventory2" @click.native="redirectToProduct" />
      </div>
    </portal>
    <item-form :fields="fields" :redirect="false" :notify="false" class="p-4 card-wrap" width="600px" ref="item" :defaultData="vendorDetails" :get="getItem" :item="id" :save="saveItem">
      <template #default="{ form }">
        <s-field-validate label="Vendor Logo" class="mb-2 profile-logo">
          <img v-if="form.vendor_profile && form.vendor_profile.url" :src="form.vendor_profile.url" />
          <img v-else src="@/assets/images/placeholder.png" />
        </s-field-validate>
        <div class="md:grid grid-cols-2">
          <s-field-validate label="Company Name" rules="required" class="mr-2 field--required">
            <STextbox type="text" v-model="form.name" placeholder="John" />
          </s-field-validate>
          <s-field-validate label="Email Id" rules="required|email">
            <STextbox type="text" v-model="form.email" placeholder="dummy@gmail.com" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate label="Owner Name" rules="required" class="mr-2 field--required">
            <STextbox type="text" v-model="form.vendor_procurement_manager_name" />
          </s-field-validate>
          <s-field-validate label="Mobile No" rules="required|mobileNo" class="mr-2 field--required">
            <STextbox type="number" v-model="form.mobile_no" placeholder="3950293940" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate label="Company Type" rules="required" class="mr-2 field--required">
            <STextbox type="text" v-model="form.company_type" />
          </s-field-validate>

          <div v-if="gstError" class="p-4 radius-3 bg-danger-lightest mb-3">
            <h5 class="text-danger">
              {{ gstError }}
            </h5>
          </div>
          <s-field-validate label="GST Number" rules="required|gst" class="field--required">
            <UpperCaseTextBoxInput type="text" :maxLength="15" v-model="gstNumber" @input="gstNumber.length == 15 && getGstData(gstNumber, form)" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate name="GST Document" label="GST Document" rules="required" class="mr-2 field--required">
            <VueFileAgentWrapper
              :baseUrl="$options.baseUrl"
              :key="1"
              v-model="gstFileData"
              endpoint="medias"
              :linkable="true"
              :params="{ used_for: 'vendor_gst_certificate' }"
              :multiple="false"
              :isDeletable="true"
              :theme="'list'"
              accept="application/pdf,application/octet-stream"
              description="Choose PDF document"
            />
          </s-field-validate>

          <s-field-validate label="Office Address" rules="required" class="mr-2 field--required">
            <s-textarea :rows="3" v-model="form.vendor_office_address" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate label="Location" rules="required" name="City" class="w-[267px] field--required">
            <treeselect
              v-model="form.vendor_product_location"
              :multiple="true"
              :options="allLocations"
              valueFormat="object"
              label="name"
              placeholder="Select your Locations"
              :normalizer="normalizer"
              :clearOnSelect="true"
            />
          </s-field-validate>

          <s-field-validate label="Accountant Name" rules="required" class="mr-2 field--required">
            <STextbox type="text" v-model="form.vendor_accountant_name" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate label="Accountant Contact No" rules="required|mobileNo" class="mr-2 field--required">
            <STextbox type="number" v-model="form.vendor_accountant_contact_no" />
          </s-field-validate>

          <s-field-validate label="Accountant Email" rules="required|email" class="mr-2 field--required">
            <STextbox type="text" v-model="form.vendor_accountant_email" />
          </s-field-validate>
        </div>

        <div class="md:grid grid-cols-2">
          <s-field-validate label="Logistic Person Name" class="mr-2">
            <STextbox type="text" v-model="form.vendor_logistic_name" />
          </s-field-validate>

          <s-field-validate label="Logistic Person Contact No" rules="mobileNo" class="mr-2">
            <STextbox type="number" v-model="form.vendor_logistic_contact_no" />
          </s-field-validate>
        </div>
      </template>

      <template #footer="{ saving }">
        <s-button type="submit" label="Update" color="primary" class="button-sm" :loader="saving" />
      </template>
    </item-form>
  </div>
</template>

<script>
import dropdownOptions from "@/data/options";
import { get, update } from "@/api/vendor/vendor.js";
import VueFileAgentWrapper from "@/components/VueFileAgentWrapper.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getCompanyFromGstNo } from "@/api/company";
import UpperCaseTextBoxInput from "@/components/form/inputs/UpperCaseTextBoxInput.vue";
// import { getLocations } from "@/api/vendor/vendor.js";

export default {
  baseUrl: process.env.VUE_APP_STORE_REST_API,
  props: ["id", "allLocations"],
  data() {
    return {
      teamMembers: [],
      isLoading: false,
      dropdownOptions,
      gstError: "",
      gstNoValid: true,
      gstFileData: null,
      gstNumber: null,
      vendorDetails: null,
      selectedLocations: [],
      normalizer(node) {
        return {
          id: node.name + "+" + node.id,
          label: node.name,
          children: node.cities,
        };
      },
      fields: [
        "name",
        "email",
        "mobile_no",
        "vendor_procurement_manager_name",
        "vendor_gst_no",
        "vendor_accountant_name",
        "vendor_accountant_contact_no",
        "vendor_accountant_email",
        "vendor_logistic_name",
        "vendor_logistic_contact_no",
        "vendor_office_address",
        "vendor_product_location",
        "vendor_gst_certificate",
        "vendor_profile",
        "company_type",
      ],
    };
  },
  computed: {},
  components: { VueFileAgentWrapper, Treeselect, UpperCaseTextBoxInput },
  created() {
    if(this.$route.params.id == '+'){
      this.$router.push({name: 'vendor-create', params: { id: '+' }})
    }
  },
  watch: {
    allLocations: function() { // watch it
      if(this.vendorDetails && this.vendorDetails.vendor_product_location){
        this.getItem(this.id);
      }
    }
  },
  methods: {
    getItem(id) {
      return get(id, { include: "cities,vendorDetail.companyType" }).then((res) => {
        this.gstFileData = res.vendor_gst_certificate;
        for (let locations of res.vendor_product_location) {
          let matchedLocation = this.allLocations.find((response) => {
            return locations.id === response.id
          });
          if (matchedLocation) {
            if (locations.cities.length === matchedLocation.cities.length) {
              this.selectedLocations.push(locations);
            } else {
              this.selectedLocations.push(...locations.cities.filter((city) => city !== null));
            }
          }
        }

        if (res.vendorDetail) {
          this.gstNumber = res.vendorDetail.vendor_gst_no;
          this.vendorDetails = {
            vendor_procurement_manager_name: res.vendorDetail.vendor_procurement_manager_name,
            vendor_office_address: res.vendorDetail.vendor_office_address,
            vendor_accountant_name: res.vendorDetail.vendor_accountant_name,
            vendor_accountant_contact_no: res.vendorDetail.vendor_accountant_contact_no,
            vendor_accountant_email: res.vendorDetail.vendor_accountant_email,
            vendor_logistic_name: res.vendorDetail.vendor_logistic_name,
            vendor_logistic_contact_no: res.vendorDetail.vendor_logistic_contact_no,
            company_type: res.vendorDetail.masterCompanyType ? res.vendorDetail.masterCompanyType.display_name : "",
            vendor_product_location: this.selectedLocations,
          };
        }
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return {
          ...res,
          role: res.role.length ? res.role[0] : null,
        };
      });
    },
    getGstData(gstNumber, form) {
      const params = {
        type: "vendor",
      };
      getCompanyFromGstNo(gstNumber, params)
        .then((res) => {
          form.name = res.name;
          form.vendor_office_address = res.office_address;
          this.gstNoValid = true;
          this.gstError = "";
        })

        .catch((error) => {
          form.name = "";
          this.gstNoValid = false;
          const title = error.data.errors ? Object.values(error.data.errors)[0] : error.data.message;

          this.gstError = Array.isArray(title) ? title[0] : title;
          this.$shilp.notify({
            title: Array.isArray(title) ? title[0] : title,
            type: "danger",
          });
        });
    },
    saveItem(id, form) {
      form["vendor_gst_no"] = this.gstNumber;
      form["gst_certificate_id"] = this.gstFileData[0] ? this.gstFileData[0].upload.data[0].id : this.gstFileData.id;
      delete form.vendor_gst_certificate;
      return update(id, form).then(() => {
        this.$shilp.notify({
          title: "Vendor updated successfully",
          type: "success",
        });
        this.$router.push({ name: "vendor-list" });
      });
    },
    redirectToProduct() {
      this.$router.push({ name: "vendor-product", params: { id: this.$route.params.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-logo {
  height: 100px;
  width: 100px;
}
::v-deep .vue-treeselect__input {
  border: none !important;
}
::v-deep .vue-treeselect__control {
  border-color: #cfd8dc;
}
</style>