export default [
  {
    path: "vendor",
    name: "vendor",
    component: require("@/views/private/vendor/manageVendor/Index").default,
    meta: {
      label: "Manage Vendors",
    },
    redirect: { name: "vendor-list" },
    children: [
      {
        path: "list",
        name: "vendor-list",
        props: true,
        component: require("@/views/private/vendor/manageVendor/List").default,
        meta: {
          label: "Vendors",
          breadcrumbs: ["dashboard", "vendor-list"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "vendor-edit",
        component: require("@/views/private/vendor/manageVendor/Edit").default,
        meta: {
          label: "Create Account",
          breadcrumbs: ["dashboard", "vendor-list", "vendor-edit"],
        },
      },
      {
        path: ":id",
        props: true,
        name: "vendor-create",
        component: require("@/views/private/vendor/manageVendor/Create").default,
        meta: {
          label: "Create Account",
          breadcrumbs: ["dashboard", "vendor-list", "vendor-create"],
        },
      },
      
      {
        path: ":id/product",
        props: true,
        name: "vendor-product",
        component: require("@/views/private/vendor/products/Index").default,
        meta: {
          label: "Products",
          breadcrumbs: ["dashboard", "vendor-list", "vendor-product"],
        },
      },
    ],
  },
];
