<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button color="primary" label="Add New User" icon="IconIcRoundPlus" :to="{ name: 'user-item', params: { id: '+' } }" />
      </div>
    </portal>

    <sp-list endpoint="users" :params="$options.params" :perPage="$options.params.limit" ref="list" :filters="filters" :attrs="columns" :actions="['search']" sortBy="created_at" sortOrder="desc">
      <template #empty>
        <list-empty title="No Users found" />
      </template>

      <template #header="{ refresh }">
        <!-- <div></div> -->
        <div class="card-wrap mb-2">
          <s-field label="Select Role">
            <s-select name="Role" placeholder="Select" v-model="filters.role" :options="$options.dropdownOptions.role" />
          </s-field>
        </div>
        <div class="filter-section">
          <SearchFilter @search="filters.search = $event" />
          <s-button-group>
            <!-- <s-button class="filter" icon="vmdi-filter" shape="circle" @click.native="$refs.list.toggleSidebar('filters')" /> -->

            <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="refresh()" />
          </s-button-group>
        </div>
      </template>

      <sp-list-table @rowClick="$router.push({ name: 'user-item', params: { id: $event.id } })">
        <template #role="{ item }">
          <span class="capitalize">{{ item.role ? item.role[0] : "N/A" }}</span>
        </template>

        <template #actions="{ item }">
          <s-button icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" @click.native="deleteUser(item.id, item.name)" />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>

<script>
import { formatDate } from "@/utilities/common";
import { remove } from "@/api/user";
import SearchFilter from "@/components/SearchFilter.vue";
import dropdownOptions from "@/data/options";

export default {
  params: {
    limit: 25,
  },
  dropdownOptions,
  formatDate,
  components: {
    SearchFilter,
  },
  data() {
    return {
      filters: {
        role: null,
        search: null,
      },
      columns: [
        {
          label: "Date & Time",
          name: "created_at",
          sortable: true,
          value: (item) => {
            return item.created_at ? this.$options.formatDate(item.created_at) : "N/A";
          },
        },
        "name",
        "username",
        "email",
        "mobile_no",
        "role",
        {
          name: "actions",
          label: "Actions",
          rowClick: false,
        },
      ],
    };
  },
  created() {},
  methods: {
    deleteUser(id, name) {
      const adminSelectedDelete = confirm(`Are you sure to delete ${name} user ?`);

      if (adminSelectedDelete) {
        remove(id).then(() => {
          this.$shilp.notify({
            title: "User Deleted Successfully",
            type: "success",
          });

          this.$refs.list.refresh();
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
