<template>
  <modal size="sm" title="Status Confirmation" id="modal-location-status" @close="closeModal">
    <template #default>
      <s-field label="Reason">
        <s-textarea :rows="4" v-model="reason" />
      </s-field>

      <div class="text-center">
        <s-button class="mt-4" align="center" color="primary" label="Submit" :disabled="reason == null"
          :loader="isSubmitting" @click.native="submit()" />
      </div>
    </template>
  </modal>
</template>
<script>
export default {
  
  data() {
    return {
      isSubmitting: false,
      reason: null,
    };
  },

  methods: {
    closeModal() { },
    submit() {
      this.isSubmitting = true; // Optionally set loading state
      this.$emit('submit', this.reason);
      this.$root.$emit("shilp-modal-close", "modal-location-status");
      this.reason = '';
      this.isSubmitting = false; // Reset after submission
    },
  },
};
</script>
<style lang="scss">
.w-modal{
  header{
    @apply justify-between
  }
}
</style>