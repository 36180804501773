<template>
  <div>
    <portal to="header-actions">
      <div class="header-actions">
        <s-button size="sm" color="primary" label="Download PO" icon="IconMdiDownload" :loader="isPODownloading" @click.native="downloadPdf" />
      </div>
    </portal>
    <div v-if="!isLoading">
      <OrderInfo :orderInfo="orderDetails" :isLoading="isLoading" />
      <div class="flex flex--fit flex--nowrap">
        <div class="w-1/2 mr-2">
          <PaymentInfo
            :orderInfo="orderDetails"
            :isLoading="isLoading"
            class="mt-2"
            :orderStatusList="orderStatusList"
            v-if="orderDetails"
            :paymentStatusList="paymentStatusList"
            @refresh="refreshOrderDetail"
          />
          <PriceInfo :orderInfo="orderDetails" :isLoading="isLoading" v-if="orderDetails" @refresh="refreshOrderDetail" />
        </div>
        <CompanyInfo :orderInfo="orderDetails" :isLoading="isLoading" class="w-1/2 mt-2" />
      </div>
      <div class="rounded overflow-hidden bg-white p-2 mt-2" v-if="orderDetails && orderDetails.assign_reason">
        <p class="font-medium mb-1">Assign reason <span class="text-gray-500 ml-2">{{ orderDetails.assign_reason }}</span></p>
      </div>
      <SubItems :orderInfo="orderDetails" :isLoading="isLoading" class="mt-2" :orderStatusList="orderStatusList" @refresh="refreshOrderDetail" />
      <DriverAndInvoiceInfo :orderInfo="orderDetails" :isLoading="isLoading" class="mt-2" @refresh="refreshOrderDetail" />
    </div>
    <Loader v-else/>
  </div>
</template>

<script>
import OrderInfo from "./OrderInfo.vue";
import PaymentInfo from "./PaymentInfo.vue";
import PriceInfo from "./PriceInfo.vue";
import DriverAndInvoiceInfo from "./DriverAndInvoiceInfo.vue";
import SubItems from "./SubItems.vue";
import CompanyInfo from "./CompanyDetails.vue";
import { getAll } from "@/api/vendor/order.js";
import { getOrderStatuses, getPaymentStatuses, downloadPOPDF } from "@/api/vendor/order.js";
import Loader from "@/components/Loader.vue";

export default {
  props: ["id"],
  data() {
    return {
      orderDetails: null,
      isLoading: false,
      orderStatusList: [],
      paymentStatusList: [],
      isPODownloading: false,
    };
  },
  components: {
    OrderInfo,
    PaymentInfo,
    PriceInfo,
    DriverAndInvoiceInfo,
    SubItems,
    CompanyInfo,
    Loader,
  },
  computed: {},
  created() {},
  mounted() {
    this.getOrderStatus();
    this.getOrderDetail();
    this.getPaymentStatus();
  },

  methods: {
    getOrderDetail() {
      this.isLoading = true;
      const params = {
        include: "brand,category,paymentStatus,paymentTerm,orderStatus,vendorOrderDrivers,orderProducts.orderStatus",
      };
      getAll(params, this.id)
        .then((res) => {
          this.orderDetails = res;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getOrderStatus() {
      const params = {
        "sort[by]": "sort_order",
        "sort[order]": "asc",
      };
      getOrderStatuses(params)
        .then((res) => {
          res.data.forEach((status) => {
            this.orderStatusList.push({
              label: status.display_name,
              value: status.id,
              name: status.name,
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPaymentStatus() {
      getPaymentStatuses()
        .then((res) => {
          res.data.forEach((status) => {
            this.paymentStatusList.push({
              label: status.display_name,
              value: status.id,
              name: status.name,
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    refreshOrderDetail() {
      this.getOrderDetail();
    },

    downloadPdf() {
      this.isPODownloading = true;
      downloadPOPDF(this.id)
        .then((res) => {
          window.open(res.url, "_blank");
        })
        .finally(() => {
          this.isPODownloading = false;
        });
    },
  },
};
</script>
