<template>
  <div class="tag" :class="`tag--${color ? color : label} tag--${size}`">
    <span class="tag__emoji">{{ emoji }}</span>
    <span class="tag__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    emoji: { type: String, default: null },
    label: { type: String, default: null },
    name: { type: String, default: null },
    color: { type: String, default: null },
    size: { type: String, default: "md" },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  // TODO Ask backend to provide color with name , label
  background: var(--light);
  color: var(--dark);
  @apply h-5 inline-flex px-2 justify-center items-center rounded-full align-top;
}
.tag__emoji {
  @apply text-xs leading-none mr-1;
}

.tag__label {
  @apply font-medium text-sm capitalize;
}

.tag--sm {
  @apply p-3;
}

.tag--md {
  @apply p-4;
}

.tag--lg {
  @apply p-5;
}
.tag--orange,
.tag--Lowest,
.tag--Request.For.Price,
.tag--Pending,
.tag--Open,
.tag--Under.Process {
  --light: #fff3e0;
  --dark: #fb8c00;
}
.tag--amber,
.tag--Invoice.Request {
  --light: #fff8e1;
  --dark: #ffa000;
}
.tag--lime,
.tag--New.Inquiry,
.tag-- {
  --light: #f9fbe7;
  --dark: #afb42b;
}
.tag--green,
.tag--success,
.tag--High.Quality,
.tag--Resolved,
.tag--Closed,
.tag--New.Bid.Alert,
.tag--New.Order {
  --light: #e8f5e9;
  --dark: #388e3c;
}
.tag--cyan {
  --light: #e0f7fa;
  --dark: #0097a7;
}
.tag--blue,
.tag--Recommended,
.tag--General,
.tag--Website.Inquiry,
.tag--Bid.Update.Alert,
.tag--Company.Registration {
  --light: #e3f2fd;
  --dark: #1976d2;
}

.tag--indigo,
.tag--Kyc.status.change {
  --light: #e8eaf6;
  --dark: #303f9f;
}

.tag--purple {
  --light: #f3e5f5;
  --dark: #7b1fa2;
}

.tag--pink {
  --light: #fce4ec;
  --dark: #c2185b;
}

.tag--red,
.tag--Fast.Selling,
.tag--failed,
.tag--Out.Of.Stock,
.tag--Credit.Limit.Crossed {
  --light: #ffebee;
  --dark: #d32f2f;
}

.tag--brown {
  --light: #efebe9;
  --dark: #6d4c41;
}
</style>
