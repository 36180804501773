var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"header-actions"}},[_c('div',{staticClass:"header-actions"},[_c('s-button',{attrs:{"color":"primary","label":"Add New Brand","icon":"IconIcRoundPlus","to":{ name: 'brand-upsert', params: { id: '+' } }}})],1)]),_c('sp-list',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreData),expression:"loadMoreData"}],ref:"list",attrs:{"pagination-mode":"infinite","params":_vm.$options.params,"perPage":_vm.$options.params.limit,"filters":_vm.filters,"attrs":_vm.columns,"endpoint":"brands","actions":['refresh', 'settings']},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var refresh = ref.refresh;
return [_c('div'),_c('div',{staticClass:"filter-section"},[_c('SearchFilter',{on:{"search":function($event){_vm.filters.search = $event}}}),_c('s-button-group',[_c('s-button',{staticClass:"refresh",attrs:{"icon":"vmdi-refresh","shape":"circle"},nativeOn:{"click":function($event){return refresh()}}})],1)],1)]}},{key:"empty",fn:function(){return [_c('list-empty')]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.$router.push({ name: 'brand-upsert', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"logo",fn:function(ref){
var item = ref.item;
return [(item.logo && item.logo.url)?_c('brand-logo',{attrs:{"url":item.logo.url}}):_vm._e()]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex gap-2"},_vm._l((item.categories),function(categories,index){return _c('div',{key:index},[_c('StatusTag',{staticClass:"category-chip",attrs:{"label":categories.name}})],1)}),0)]}},{key:"tag",fn:function(ref){
var item = ref.item;
return [(item.tag)?_c('Tag',{attrs:{"label":item.tag}}):_vm._e()]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(_vm.isBrandDeleteGranted)?_c('s-button',{attrs:{"icon":"IconMdiTrashCanOutline","shape":"circle","color":"danger","theme":"muted"},nativeOn:{"click":function($event){return _vm.deleteBrand(item.id, item.name)}}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }