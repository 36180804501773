
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M3 19.5v-6.462h6.462V19.5zM7.115 9l4.116-6.77L15.346 9zm14.499 12.835l-2.612-2.612q-.467.37-1.041.573q-.574.204-1.23.204q-1.567 0-2.65-1.082T13 16.27t1.082-2.649t2.649-1.082t2.649 1.082t1.082 2.65q0 .65-.204 1.214t-.548 1.031l2.611 2.612zM16.73 19q1.146 0 1.938-.792t.793-1.939t-.793-1.938t-1.938-.793t-1.939.793T14 16.269t.792 1.939t1.939.792"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiCategory",
};
</script>