<template>
  <div class="section">
    <div class="shimmer-box">
      <div v-for="i in 10" :key="`shimmer-${i}`" class="shimmer">
        <p class="w-full shimmer-card"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.section {
  @apply animate-pulse mt-12;
}

.shimmer-box {
  @apply p-4 divide-y;
}

.shimmer {
  @apply flex space-x-2 py-2;
  background: transparent !important;
}

.shimmer-card {
  @apply bg-gray-300 h-8 rounded;
}
</style>
