<template>
  <div>
    <List :params="params" :columns="columns" actionRoute="vendor-bids-list" module="unassigned" />
  </div>
</template>

<script>
import List from "../List.vue";
import { getReadClassList } from "@/utilities/common";
export default {
  data() {
    return {
      columns: [
        {
          name: "order_no",
          label: "ID",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "category_id",
          label: "Brand",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "buyerName",
          label: "Buyer Name",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "project_name",
          label: "Project Name",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "qty",
          label: "Quantity",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "buyer_total",
          label: "Buyer Amount",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "vendor_bids_count",
          label: "Vendors",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "created_at",
          label: "Date & Time",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "updated_at",
          label: "Updated At",
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
        {
          name: "action",
          label: "Action",
          rowClick: false,
          classList(row) {
            return getReadClassList(row.read_status);
          },
        },
      ],
      params: {
        limit: 25,
        include: "orderStatus, category, brand",
        "sort[order]": "desc",
        "sort[by]": "updated_at",
      },
    };
  },
  components: {
    List,
  },
  methods: {
    getReadClassList,
  },
};
</script>