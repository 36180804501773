<template>
  <div>
    <div class="flex items-center justify-end gap-5 mt-2">
      <SearchFilter :value="filters.brand_mapping_search" @search="updateSearchFilter" />
      <s-field>
        <s-select name="Role" placeholder="Select Status" v-model="filters.isActiveCity" :options="$options.dropdownOptions.locationStatus" />
      </s-field>
      <s-button class="refresh" icon="vmdi-refresh" shape="circle" @click.native="getLocations()" />
    </div>
    <ListShimmer v-if="isLocationApiCalling" />
    <div v-else>
      <div v-for="item in locationList" :key="`item-${item.id}`" class="bg-white mt-2 py-2">
        <div class="flex justify-between gap-2 items-center border-b py-2 mx-4" @click="toggleState(item.id)">
          <div class="flex items-center">
            <div v-if="item.brand_cities.length">
              <a v-if="stateRowIndex != item.id" :key="'item-row' + item.id" class="mt-1"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowDown"></s-icon></a>
              <a v-if="stateRowIndex == item.id" :key="'item-row' + item.id" class="mt-1"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowUp"></s-icon></a>
            </div>
            <div class="ml-2">{{ item.name }}</div>
          </div>
          <div class="flex items-center gap-2">
            <div :key="`state-modal-${item.id}`" @click.prevent.stop="toggleSwitchState(item, null, false)">
              <s-switch
                :id="`state-status-switch-${item.id}`"
                :name="`state-status-switch-${item.id}`"
                :key="`state-status-switch-${item.id}`"
                v-model="item.is_active"
                :on-value="true"
                :off-value="false"
                shape="pill"
                color="primary"
                size="xs"
              />
            </div>
            <s-button icon="IconMdiTrashCanOutline" shape="circle" color="danger" theme="muted" class="!h-6 !w-6 p-1" @click.native.stop="deleteLocation(item.name, { state_id: item.id })" />
          </div>
        </div>
        <div :ref="`order-product-table-${item.id}`" v-if="isStateRowExpanded && stateRowIndex == item.id && item.brand_cities.length" class="pt-2 ml-10">
          <div v-for="brandCity in item.brand_cities" :key="`city-${item.id + brandCity.id}`" class="bg-white">
            <div class="flex justify-between items-center border-b py-2 mx-4" @click="toggleCities(brandCity.id)">
              <div class="flex items-center">
                <div v-if="brandCity.location_categories.length">
                  <a v-if="cityRowIndex != brandCity.id" :key="'city-row' + brandCity.id" class="mt-1"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowDown"></s-icon></a>
                  <a v-if="cityRowIndex == brandCity.id" :key="'city-row' + brandCity.id" class="mt-1"><s-icon class="text-2xl" name="IconIcOutlineKeyboardArrowUp"></s-icon></a>
                </div>
                <div class="ml-2">{{ brandCity.name }}</div>
              </div>
              <div class="flex justify-between items-center gap-2">
                <a
                  class="cursor-pointer text-blue-600"
                  @click.stop="
                    $router.push({ name: 'vendor-list', query: { cityId: brandCity.id, categoryId: brandCity.location_categories.map((category) => ({ id: category.id, name: category.name })) } })
                  "
                  >Redirect to vendor</a
                >
                <div class="flex items-center gap-1 mx-2">
                  <div :key="`city-modal-${item.id + brandCity.id}`" @click.prevent.stop="toggleSwitchState(item, brandCity, true)">
                    <s-switch
                      :id="`city-status-switch-${item.id + brandCity.id}`"
                      :key="`city-status-switch-${item.id + brandCity.id}`"
                      v-model="brandCity.is_active"
                      :on-value="true"
                      :off-value="false"
                      shape="pill"
                      color="primary"
                      size="xs"
                    />
                  </div>
                  <InformationButton v-if="brandCity.reason" v-tooltip.bottom="brandCity.reason" color="primary" />
                </div>

                <s-button
                  icon="IconMdiTrashCanOutline"
                  shape="circle"
                  color="danger"
                  theme="muted"
                  class="h-4 w-4 p-1"
                  @click.native.stop="deleteLocation(brandCity.name, { city_id: brandCity.id })"
                />
              </div>
            </div>
            <div :ref="`order-product-table-${brandCity.id}`" v-if="isCityRowExpanded && cityRowIndex == brandCity.id && brandCity.location_categories.length" class="pt-2 mx-10">
              <div class="bg-white mb-3 p-3 border">
                <!-- <div class=""> -->
                <!-- Header Row -->
                <div class="grid grid--6 grid-cols-6 gap-4 p-2">
                  <div class="font-bold">Categories</div>
                  <div class="font-bold col-span-4">Brands</div>
                  <div class="font-bold">Actions</div>
                </div>

                <!-- Data Rows -->
                <div v-for="(locationCategory, categoryIndex) in brandCity.location_categories" :key="`category-${categoryIndex}`" class="border-t">
                  <div class="grid grid--6 grid-cols-6 gap-4 py-2">
                    <div class="p-2">
                      <!-- Category Name -->
                      {{ locationCategory.name }}
                    </div>
                    <div class="flex flex-wrap col-span-4">
                      <!-- Brands List -->
                      <div v-for="(brand, brandIndex) in locationCategory.location_brands" :key="brandIndex" class="p-2 -mx-1">
                        <StatusTag class="location-chip" :label="brand.name" />
                      </div>
                    </div>
                    <div class="flex gap-2">
                      <s-button
                        :key="categoryIndex + 'cat_edit'"
                        title="Edit"
                        icon="IconMdiPencilOutline"
                        size="sm"
                        color="info"
                        theme="muted"
                        shape="circle"
                        @click.native="onEdit(locationCategory, brandCity)"
                      />
                      <s-button
                        :key="categoryIndex + 'cat_delete'"
                        title="Delete"
                        icon="IconMdiTrashCanOutline"
                        size="sm"
                        color="danger"
                        theme="muted"
                        shape="circle"
                        @click.native="onDelete(locationCategory, brandCity)"
                      />
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <StatusConfirmationModal @submit="handelSubmit($event)" />
    </div>
  </div>
</template>

<script>
import { get, remove, locationStatus } from "@/api/location.js";
import ListShimmer from "@/components/ListShimmer.vue";
import StatusConfirmationModal from "./StatusConfirmationModal.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import dropdownOptions from "@/data/options";
import StatusTag from "@/components/StatusTag.vue";
import InformationButton from "@/components/InformationButton";

export default {
  dropdownOptions,
  data() {
    return {
      locationList: [],
      sortedBrands: [],
      stateRowIndex: null,
      cityRowIndex: null,
      categoryRowIndex: null,
      isStateRowExpanded: false,
      isCityRowExpanded: false,
      isCategoryRowExpanded: false,
      filters: {
        search: null,
        isActiveCity: null,
      },
      isLocationApiCalling: false,
      toggleSwitch: false,
      status: "",
      isActiveAll: false,
      brandState: {},
      brandCity: {},
      isCity: null,
    };
  },
  components: {
    ListShimmer,
    StatusConfirmationModal,
    SearchFilter,
    StatusTag,
    InformationButton,
  },

  mounted() {
    this.getLocations();
  },
  watch: {
    "filters.isActiveCity"() {
      this.getLocations();
    },
  },
  methods: {
    async updateSearchFilter(value) {
      this.filters.search = value;
      await this.getLocations();
    },
    getLocations() {
      this.isLocationApiCalling = true;
      let parms = {
        filter: {
          brand_mapping_search: this.filters.search,
          is_active_city: this.filters.isActiveCity,
        },
      };
      get(parms)
        .then((response) => {
          this.locationList = response.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLocationApiCalling = false;
        });
    },
    toggleState(index) {
      if (this.isStateRowExpanded && this.stateRowIndex === index) {
        this.stateRowIndex = null;
        this.isStateRowExpanded = false;
      } else {
        this.stateRowIndex = index;
        this.isStateRowExpanded = true;
        this.cityRowIndex = null;
        this.isCityRowExpanded = false;
      }
    },

    toggleCities(index) {
      if (this.isCityRowExpanded && this.cityRowIndex === index) {
        this.cityRowIndex = null;
        this.isCityRowExpanded = false;
      } else {
        this.cityRowIndex = index;
        this.isCityRowExpanded = true;
        this.categoryRowIndex = null;
        this.isCategoryRowExpanded = false;
      }
    },

    toggleCategory(index) {
      if (this.isCategoryRowExpanded && this.categoryRowIndex == index) {
        this.categoryRowIndex = null;
        this.isCategoryRowExpanded = false;
      } else {
        this.categoryRowIndex = index;
        this.isCategoryRowExpanded = true;
      }
    },
    getParsedBrands(brands) {
      return brands;
    },

    deleteLocation(name, data) {
      this.$shilp.confirm({
        title: "Delete confirmation",
        message: `Are you sure you want to delete ${name} ?`,
        okLabel: "Yes",
        ok: ({ close }) => {
          remove(data)
            .then(() => {
              close();
              this.$shilp.notify({
                title: "Product deleted successfully.",
                type: "success",
              });
              this.getLocations();
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    async toggleSwitchState(brandState, brandCity, isCity) {
      await this.$root.$emit("shilp-modal-open", "modal-location-status");
      (this.brandState = brandState), (this.brandCity = brandCity);
      this.isCity = isCity;
    },

    onEdit(category, city) {
      this.$emit("onEditCategory", { category, city });
    },

    onDelete(category, city) {
      const postData = {
        category_id: category.id,
        city_id: city.id,
      };
      this.$shilp.confirm({
        title: "Delete confirmation",
        message: `Are you sure you want to delete ${category.name} ?`,
        okLabel: "Yes",
        ok: ({ close }) => {
          remove(postData)
            .then(() => {
              close();
              this.$shilp.notify({
                title: "Category deleted successfully.",
                type: "success",
              });
              this.getLocations();
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    async handelSubmit(reason) {
      const data = {
        [this.isCity ? "city_id" : "state_id"]: this.isCity ? this.brandCity.id : this.brandState.id,
        is_active: this.isCity ? !this.brandCity.is_active : !this.brandState.is_active,
        reason: reason,
      };
      await locationStatus(data)
        .then(async (data) => {
          if (!this.isCity) {
            this.brandState.is_active = !this.brandState.is_active;
            this.brandState.reason = reason;
            this.brandState.brand_cities.map((item) => {
              item.is_active = this.brandState.is_active;
              item.reason = reason;
            });
          }
          if (this.isCity) {
            const selectedCity = this.brandState.brand_cities.find((item) => item.id === this.brandCity.id);
            if (selectedCity) {
              this.brandCity.is_active = !this.brandCity.is_active;
              this.brandCity.reason = reason;
            }
            const allCityStatus = this.brandState.brand_cities.find((item) => item.is_active == true);
            if (allCityStatus) {
              this.brandState.is_active = true;
            } else {
              this.brandState.is_active = false;
            }
          }
          this.$shilp.notify({
            title: data.message,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: data.message,
            type: "error",
          });
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss">
.flex > * {
  flex: none;
}

.location-chip {
  background-color: #eceff1;
  color: black;
}

.popover-inner {
  max-height: 400px;
  overflow-y: scroll;
}

.refresh {
  background-color: white;
}
</style>