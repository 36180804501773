
export default {
  companyStatus: [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "InActive",
      value: "inactive",
    },
  ],
  teamMembers: [
    {
      label: "Raj Sharma ( Support )",
      value: "raj",
    },
    {
      label: "Akash Patel ( Manager )",
      value: "akash",
    },
    {
      label: "Ajav C ( Account Manager )",
      value: "accountant",
    },
  ],
  brandTags: [
    {
      label: "Lowest",
      value: "Lowest",
    },
    {
      label: "Fast Selling",
      value: "Fast Selling",
    },
    {
      label: "High Quality",
      value: "High Quality",
    },
    {
      label: "Recommended",
      value: "Recommended",
    },
  ],

  invoiceStatus: [
    {
      label: "Pending",
      value: 1,
    },
    {
      label: "Uploaded",
      value: 0,
    },
  ],
  role: [
    // {
    //   label: "Super Admin",
    //   value: "super_admin",
    // },
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Manager",
      value: "manager",
    },
    {
      label: "Executive",
      value: "executive",
    },
    // {
    //   label: "Business Associate",
    //   value: "business_associate",
    // },
  ],
  locationStatus: [
    {
      label: "All",
      value: null,
    },
    {
      label: 'Active',
      value: 'true'
    },
    {
      label: 'InActive',
      value: 'false'
    }
  ],
};
