<template>
  <item-form :submitOnEnter="false" :fields="fields" :redirect="true" class="p-4 card-wrap" width="400px" ref="item" :get="getItem" :item="id" > <!--:save="saveItem"-->
    <template #default="{ form }">
      <div>
        <s-field-validate label="Name" rules="required" name="name">
          <s-textbox v-model="form.name" placeholder="Enter Category Name" />
        </s-field-validate>

        <s-field-validate class="mt-2" label="Status" name="status" rules="required">
          <v-select :options="$options.options.companyStatus" label="label" placeholder="Select" :reduce="(item) => item.value" v-model="form.status" />
        </s-field-validate>
      </div>
    </template>
    <template #footer="{ saving, form }">
      <div>
        <s-button :label="$route.params.id == '+' ? 'Save' : 'Update'" color="primary" type="button" :loader="saving" @click.native="saveItem(form)"/>
      </div>
    </template>
  </item-form>
</template>

<script>
import { update, create, getDetail, } from "@/api/category";
import options from "@/data/options.js";
export default {
  options,
  components: {},
  props: ["id"],
  data() {
    return {
      fields: [
        "name",
        {
          name: "status",
          value: "active",
        },
      ],
    };
  },
  methods: {
    getItem(id) {
      return getDetail(id).then((res) => {
        this.$root.$emit("shilp-breadcrumbs-label", this.$route.name, res.name);
        return res;
      });
    },

    saveItem(form) {
      const id = this.$route.params.id;
      if (id != '+') {
        update(id, form).then(() => {
          this.$shilp.notify({
          title: "Category updated successfully.",
          type: "success",
        });
        this.$router.push({ name: "category-listing"});
        });
      } else {
        create(form).then(() => {
          this.$shilp.notify({
          title: "Category added successfully.",
          type: "success",
        });
        this.$router.push({ name: "category-listing"});
        });
      }
    },
  },
};
</script>

<style></style>
