<template>
  <modal size="md" title="Assign Confirmation" id="modal-assign-confirmation" @close="closeModal">
    <template #default>
      <table class="w-full">
            <tr class="text-xs">
                <th class="py-2 text-left">Grade</th>
                <th class="py-2 text-left">Size/Packing</th>
                <th class="py-2 text-left">Vendor Price</th>
                <th class="py-2 text-left">Buyer Price</th>
                <th class="py-2 text-left">Difference Price</th>
                <th class="py-2 text-left">Margin</th>
            </tr>
            <tr v-for="bid in bidDetails" :key="bid.id" class="font-bold pt-2 text-start">
                <td class="">{{ bid.grade_name }}</td>
                <td class="">{{ bid.attribute_option_name }}</td>
                <td class="">{{ formatPrice(bid.price) }}</td>
                <td class="">{{ formatPrice(bid.buyer_unit_price) }}</td>
                <td >
                  <div v-if="bid.price < bid.buyer_unit_price" class="flex items-center text-green-600">
                    <p class="mr-1">{{ formatPrice(bid.buyer_unit_price - bid.price ) }}</p>
                  <s-icon name="IconMdiArrowUp" />
                  </div>
                  <div v-else class="flex items-center text-red-600">
                    <p class="mr-1">{{ formatPrice(bid.price - bid.buyer_unit_price) }}</p>
                  <s-icon name="IconMdiArrowDown" v-if="bid.price > bid.buyer_unit_price"/>
                  </div>
                </td>
                <td>
                  <div class="text-green-600">
                    <span :class="bid.marginCount > 0 ? 'text-green-600' : 'text-red-600'">{{bid.marginCount.toFixed(2)}}%</span>
                  </div>
                </td>
            </tr>
      </table>
      <p class="my-4 text-xs">The price difference between the vendor and buyer prices for one or more items is unusual. Please review the statistics above. If you still wish to assign the order, kindly provide a reason and click the <span class="font-bold">'I confirm'</span> button.</p>
      <s-field label="Reason (optional)" class="">
      <s-textarea :rows="2" v-model="reason" />
    </s-field>
      
    </template>
    <template #footer-actions>
      <div class="flex gap-2">
        <s-button
        class=""
        theme="muted"
        color="grey"
        size="button--sm"
        label="Cancel"
        @click.native="closeModal()"
      />
      <s-button
        class=""
        color="primary"
        size="button--sm"
        label="I confirm"
        @click.native="submit()"
      />
      </div>
    </template>
  </modal>
</template>
<script>
import { formatPrice } from "@/utilities/common";
export default {

  props: ["bidDetails"],
  
  data() {
    return {
      reason: null
    };
  },

  methods: {
    formatPrice,
    closeModal() { 
      this.$root.$emit("shilp-modal-close", "modal-assign-confirmation");
    },
    submit() {
      this.$emit("assign-order", this.reason);
    },
  },
};
</script>