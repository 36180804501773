<template>
    <modal size="sm" title="Bid Update Alert" id="modal-bid-update-alert" :close="false">
      <!-- @close="close" -->
      <template #default>
        <p>The bid has been updated and the page will be auto refreshed on a close of this popup.</p>
      </template>
      <template #footer-actions>
      <s-button type="button" @click.native="close" label="Close" color="primary" class="button--sm" />
      </template>
    </modal>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
  
    methods: {
      close(){
        try{
          this.$emit('refreshBid')
          this.$root.$emit('shilp-modal-close', 'modal-bid-update-alert');
        }catch{
          console.log('error')
        }
      }
    },
  };
  </script>
  
  <style></style>
  