<template>
  <div>
    <div v-if="bidDetails && !isLoading">
      <div class="flex flex--fit flex--nowrap mb-2" v-if="bidDetails && !isLoading">
        <div class="bg-white p-3 radius-2 mr-2 w-full">
          <div class="grid grid--6">
            <div>
              <p class="font-medium text-gray-500">Vendor Name</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.user && bidDetails.user.name }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Mobile No</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.user && bidDetails.user.mobile_no }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Order ID</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.order_no }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Category</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.category.name }}</p>
            </div>
            <div class="flex flex--fit">
              <img :src="bidDetails.brand.logo.url" class="w-5 h-5 mr-1" />
              <div>
                <p class="font-medium text-gray-500">Brand</p>
                <p class="text-sm font-medium text-gray-600">{{ bidDetails.brand.name }}</p>
              </div>
            </div>
            <div>
              <p class="font-medium text-gray-500">Assign Status</p>
              <p class="text-sm font-medium text-gray-600 w-1/2">
                <StatusTag :label="bidDetails.bid_status.name" :name="bidDetails.bid_status.name.toLowerCase()"
                  class="flex flex--fit justify-center mt-1" />
              </p>
            </div>
          </div>

          <div class="grid grid--6 mt-3">
            <div>
              <p class="font-medium text-gray-500">Buyer Total Amount</p>
              <p class="text-sm font-medium text-gray-600">{{ formatPrice(bidDetails.vendorOrder.order.total) }}</p>
            </div>
            <div>
              <p class="font-medium text-gray-500">Delivery By</p>
              <p class="text-sm font-medium text-gray-600">{{ formatDate(bidDetails.delivery_date) }}</p>
            </div>

            <div class="!grow">
              <div class="flex flex--fit mb-2">
                <p class="font-medium text-gray-500">Payment Terms</p>
                <v-popover ref="popover">
                  <template #popover>
                    <div class="flex">
                      <s-field-validate name="payment terms" label="Enter Payment Terms" :rules="`required`"
                        class="mb-2 field--required" after="day(s)">
                        <STextbox type="number" v-model="paymentTermDays" />
                      </s-field-validate>
                      <s-button label="save" class="button--primary text-center" @click.native="updateBid()"
                        :loader="updateBidLoader" :disabled="!paymentTermDays" />
                    </div>
                  </template>
                  <a class="text-blue-500 font-medium cursor-pointer pr-3 ml-2">Edit</a>
                </v-popover>
              </div>
              <StatusTag v-if="updatedPaymentTerm" :label="updatedPaymentTerm"
                :name="updatedPaymentTerm.toLowerCase()" />
            </div>
            <div class="note-grid">
              <p class="font-medium text-gray-500 mr-2 mt-1">Note</p>
              <p class="text-sm font-medium text-gray-600">{{ bidDetails.info || "NA" }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white p-3 radius-2">
        <div class="flex flex--fit justify-start">
          <div class="w-4/12">
            <p class="font-medium text-gray-500">Buyer Name</p>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.buyerName[0] }}</p>
          </div>
          <div class="w-4/12">
            <p class="font-medium text-gray-500 ">Project Name</p>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.project_name }}</p>
          </div>
          <div class="w-4/12">
            <p class="font-medium text-gray-500 w-4/12">Payment Terms</p>
            <p class="text-sm font-medium text-gray-600 w-8/12">{{ bidDetails.vendorOrder.order.paymentTerms.description
              }}</p>
          </div>
        </div>
        <div class="mt-3">
            <div class="flex flex--fit items-center">
              <p class="font-medium text-gray-500">Project Location</p>
              <a class="flex flex--fit items-center border border-gray-200 rounded-full px-1 cursor-pointer"
                :class="bidDetails.vendorOrder.latitude && bidDetails.vendorOrder.longitude ? 'ml-2' : ''"
              v-if="bidDetails.vendorOrder.latitude && bidDetails.vendorOrder.longitude"
                @click="redirectToGoogleMaps(bidDetails.vendorOrder)">
                <s-icon name="IconGoogleMapMarker" class="mr-px" /> 
                <p class="leading-loose font-medium text-gray-400">See on map</p>
              </a>
              <v-popover ref="popover" popoverWrapperClass="w-[440px]">
                <template #popover>
                  <ValidationObserver>
                    <template #default="{ invalid }">
                      <div class="flex flex-nowrap justify-center">
                        <s-field-validate rules="required" name="Latitude" class="mr-2">
                          <s-textbox v-model="latitude" type="number" placeholder="Latitude" />
                        </s-field-validate>
                        <s-field-validate rules="required" name="Longitude">
                          <s-textbox v-model="longitude" type="number" placeholder="Longitude" />
                        </s-field-validate>
                      </div>
                      <s-button label="Generate Map Link" color="success" class="my-2 w-full"
                        @click.native="generateMapLink()" />
                      <s-field-validate name="Google Map Link" rules="required">
                        <s-textbox v-model="google_map_link" type="text" placeholder="Google Map Link" />
                      </s-field-validate>
                      <div class="flex justify-end">
                        <s-button label="Save" color="primary" :disabled="invalid" class="mt-2 w-32"
                          @click.native="updateProjectMapLocation()" />
                      </div>
                    </template>
                  </ValidationObserver>
                </template>
                <s-button label="Add Geo Location" title="Edit" icon="IconMdiPencilOutline" size="sm" color="info" theme="muted" 
                class="ml-2" />
              </v-popover>
            </div>
            <p class="text-sm font-medium text-gray-600">{{ bidDetails.vendorOrder.delivery_address }}</p>
        </div>

        <div class="mt-3 bg-red-300 p-2 rounded-md w-auto" v-if="bidDetails.vendorOrder.order.additional_instruction">
          <p class="font-medium text-gray-700">Additional Requirements</p>
          <p class="text-sm font-medium text-gray-800">{{ bidDetails.vendorOrder.order.additional_instruction}}</p>
        </div>
      </div>
      <div class="bg-white rounded p-2 mt-2">
        <ValidationObserver #default="{ invalid }">
          <div class="bg-gray-200 radius-2 mt-2 p-2 flex flex--fit justify-between">
            <p class="font-medium v-list__header">Sub Items</p>
            <div v-if="bidWarningObj && bidWarningObj.bid_count"
              @click="$router.push({ name: 'vendor-bids-list', params: { id: vendorOrderId } })"
              class="text-orange-600 bg-orange-200 p-2 rounded-md font-semibold flex gap-2 cursor-pointer">
              <s-icon name="IconMdiAlertOutline" class="h-[18px] text-orange-600" />
              <p>{{ bidWarningObj.message }}</p>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex gap-1">
                <p>GST Included</p>
                <v-popover trigger="hover" popoverWrapperClass="bg-white p-0">
                    <template #popover>
                  <ul>
                    <li class="p-1 bg-transparent border-b-2 rounded-none" v-for="(gradeDetail, index) in gradeWisePercentage" :key="index">
                      {{ gradeDetail.grade }} - {{ gradeDetail.percentage }}%
                    </li>
                  </ul>
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
              </div>
              <s-button label="Save" class="button--accent button-sm text-center !h-[30px]"
                @click.native="saveAndAssign(invalid, 'save')" />
              <s-button label="Save & Assign" class="button--success button-sm text-center !h-[30px]"
                @click.native="saveAndAssign(invalid, 'assign')" />
            </div>
          </div>
          <table class="mt-2 overflow-y-scroll w-full bg-white rounded">
            <tr class="border-t border-b">
              <th class="text-left p-2 w-[10%]">Grade</th>
              <th class="text-left w-[10%]">Size/Packing</th>
              <th class="text-left w-[12%]">Quantity<span class="text-danger-700">*</span></th>
              <th class="text-left w-[17%]">Vendor Price<span class="text-danger-700">*</span></th>
              <th class="text-left w-[20%]">Vendor Total Price</th>
              <th class="text-left w-[17%]">Buyer Price<span class="text-danger-700">*</span></th>
              <th class="text-left w-[28%]">Buyer Total Price</th>
            </tr>
            <tr v-for="(detail, bidIndex) in bidDetails.bid_details" :key="'bidId1' + bidIndex" class="border-b">
              <td class="p-3">{{ detail.grade_name }}</td>
              <td>{{ detail.attribute_option_name }}</td>
              <td>
                <s-field-validate rules="required" class="w-[100px] mb-1" :after="detail.unit_name">
                  <STextbox type="number" v-model.number="detail.dispatch_quantity" class="!bg-transparent" />
                </s-field-validate>
              </td>
              <td>
                <div class="flex flex--fit items-center ">
                  <s-field-validate rules="required" class="mr-2 w-[130px]" before="₹">
                    <STextbox type="number" v-model.number="detail.price" class="!bg-transparent"
                      @input="calculateVendorGSTPrice(detail)" @blur.native="afterVendorPriceChange(detail); onVendorPriceChange(detail);" />
                    <!---->
                  </s-field-validate>
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      <PriceBreakUp :gstPercentage="detail.gst_percentage" :gstAmount="detail.single_gst_price"
                        :basePrice="detail.single_base_price" />
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>
              </td>
              <td>
                <div class="flex flex--fit items-center">
                  <p class="mr-2">{{ formatPrice(updatedTotalPrice(detail.dispatch_quantity, detail.price)) }}</p>
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      {{ calculateVendorTotalGSTPrice(detail) }}
                      <PriceBreakUp :gstPercentage="detail.gst_percentage" :gstAmount="detail.vendor_total_gst_price"
                        :basePrice="detail.vendor_total_base_price" />
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>

              </td>

              <td>
                <div class="flex flex--fit items-center">
                  <s-field-validate rules="required" before="₹" class="w-[130px]">
                    <STextbox type="number" v-model.number="detail.buyer_unit_price" class="!bg-transparent mr-1"
                      :class="{ 'cursor-not-allowed': detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled }"
                      :disabled="detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled"
                      :ref="`price-${detail.id}`" @input="calculateBuyerGSTPrice(detail)" />
                  </s-field-validate>
                  <s-button :key="bidIndex" title="Edit" icon="IconMdiPencilOutline" size="sm" color="info"
                    theme="muted" shape="circle" class="mr-2"
                    v-if="detail.isBuyerPriceDisabled === undefined || detail.isBuyerPriceDisabled"
                    @click.native="toggleBuyerPriceDisability(detail)" />
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      {{ calculateBuyerGSTPrice(detail) }}
                      <PriceBreakUp :gstPercentage="detail.gst_percentage" :gstAmount="detail.buyer_gst_price"
                        :basePrice="detail.buyer_base_price" />
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>

              </td>
              <td>
                <div class="flex flex--fit items-center">
                  <p class="mr-2">{{ formatPrice(updatedTotalPrice(detail.dispatch_quantity, detail.buyer_unit_price))
                    }}</p>
                  <v-popover trigger="hover" popoverWrapperClass="min-w-max">
                    <template #popover>
                      {{ calculateBuyerTotalGSTPrice(detail) }}
                      <PriceBreakUp :gstPercentage="detail.gst_percentage" :gstAmount="detail.buyer_total_gst_price"
                        :basePrice="detail.buyer_total_base_price" />
                    </template>
                    <s-button icon="IconMdiInformationOutline" size="md" color="secondary" theme="link" />
                  </v-popover>
                </div>

              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4" class="pt-2">
                <h4 class="price-label">Loading</h4>
              </td>
              <td colspan="2" class="pt-2">
                <p>{{ formatPrice(bidDetails.loading) }}</p>
              </td>
              <td class="pt-2">
                <p>{{ formatPrice(bidDetails.loading) }}</p>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Unloading</h4>
              </td>
              <td colspan="2">
                <p>{{ formatPrice(bidDetails.unloading) }}</p>
              </td>
              <td>
                <p>{{ formatPrice(bidDetails.unloading) }}</p>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Freight</h4>
              </td>
              <td colspan="2">
                <p>{{ formatPrice(bidDetails.freight) }}</p>
              </td>
              <td colspan="2">
                <p>{{ formatPrice(bidDetails.freight) }}</p>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="4">
                <h4 class="price-label">Service GST</h4>
              </td>
              <td colspan="2">
                <p>{{ formatPrice(bidDetails.freight_gst_price + bidDetails.loading_gst_price +
                  bidDetails.unloading_gst_price) }}</p>
              </td>
              <td colspan="2">
                <p>{{ formatPrice(bidDetails.freight_gst_price + bidDetails.loading_gst_price +
                  bidDetails.unloading_gst_price) }}</p>
              </td>
            </tr>

            <tr class="order-total">
              <td colspan="2">
                <h4 class="price-label">Total</h4>
              </td>

              <td colspan="2">
                <h5>{{ calculateQuantity(bidDetails) }} {{ bidDetails.unit_name }}</h5>
              </td>

              <td colspan="2">
                <h5>{{ formatPrice(calculateVendorTotalPrice(bidDetails)) }}</h5>
              </td>
              <td colspan="2">
                <h5>{{ calculateBuyerTotalPrice(bidDetails) }}</h5>
              </td>
            </tr>
          </table>
        </ValidationObserver>
      </div>
    </div>
    <Loader class="flex" v-else />
    <AssignBidConfirmation ref="assignBidConfirmation" :bid-details="bidDetails && bidDetails.bid_details"
      @assign-order="assignOrder" />
      <BidUpdateAlert @refreshBid="getBids()"/>
  </div>
</template>

<script>
import { formatPrice } from "@/utilities/common";
import { getBidDetail, updateBidAssign, updateMultipleBidPrice, updateBidDetail, getVendorTMTBarPrice } from "@/api/vendor/order.js";
import Loader from "@/components/Loader.vue";
import StatusTag from "@/components/StatusTag.vue";
import PriceBreakUp from "@/components/order/PriceBreakUp.vue";
import { formatDate } from "@/utilities/common";
import { mapGetters } from "vuex";
import AssignBidConfirmation from "./AssignBidConfirmation.vue";
import { updateGeoLocation } from "@/api/project.js";
import { getLowerBidCount } from "@/api/vendor/vendor.js"
import bus from "@/bus";
import BidUpdateAlert from "./BidUpdateAlert.vue";

export default {
  props: ["id"],
  data() {
    return {
      isLoading: false,
      fields: ["quantity", "total", "status"],
      bidDetails: null,
      bidId: null,
      paymentTermDays: null,
      updateBidLoader: false,
      updatedPaymentTerm: null,
      showAssignConfirmation: false,
      google_map_link: null,
      latitude: null,
      longitude: null,
      bidWarningObj: null,
      gradeWisePercentage: [],
      vendorOrderId: null
    };
  },
  components: {
    Loader,
    StatusTag,
    PriceBreakUp,
    AssignBidConfirmation,
    BidUpdateAlert
  },
  created() {
    bus.$on("new-bid-arrived", (notifyData) => {
      if(notifyData.data.notifiable_type == 'admin_vendor_bid' && notifyData.data.notifiable_id == this.$route.params.bidId){
        this.$root.$emit('shilp-modal-open', {id: 'modal-bid-update-alert'});
      }
    });
  },
  mounted() {
    this.bidId = this.$route.params.bidId;
    this.getBids();
  },
  computed: {
    ...mapGetters({
      hasAllPermissions: "hasAllPermissions",
    }),
  },
  methods: {
    formatPrice,
    formatDate,

    calculateVendorGSTPrice(detail) {
      detail.single_base_price = (detail.price / (100 + detail.gst_percentage)) * 100;
      detail.single_gst_price = detail.price - detail.single_base_price;
    },
    async afterVendorPriceChange(product, totalBidPrice) {
      const totalPrice = totalBidPrice ? totalBidPrice : await this.calculateVendorTotalPrice(this.bidDetails);
      getLowerBidCount(this.bidDetails.id, { vendor_total_price: totalPrice }).then((res) => {
        this.bidWarningObj = res;
      })
    },

    getGSTPercentageGradeWise(){
      this.bidDetails.bid_details.forEach((detail) => {
        const isGradeIncluded = this.gradeWisePercentage.some(grade => grade.grade === detail.grade_name);
        if (!isGradeIncluded) {
          this.gradeWisePercentage.push({
            grade: detail.grade_name,
            percentage: detail.gst_percentage
          });
        }
      });
    },

    calculateBuyerGSTPrice(detail) {
      detail['buyer_base_price'] = (detail.buyer_unit_price / (100 + detail.gst_percentage)) * 100;
      detail['buyer_gst_price'] = detail.buyer_unit_price - detail.buyer_base_price;
    },

    calculateVendorTotalGSTPrice(detail) {
      detail['vendor_total_base_price'] = (this.updatedTotalPrice(detail.dispatch_quantity, detail.price) / (100 + detail.gst_percentage)) * 100;
      detail['vendor_total_gst_price'] = (this.updatedTotalPrice(detail.dispatch_quantity, detail.price) * detail.gst_percentage) / (100 + detail.gst_percentage);
    },

    calculateBuyerTotalGSTPrice(detail) {
      detail['buyer_total_base_price'] = (this.updatedTotalPrice(detail.dispatch_quantity, detail.buyer_unit_price) / (100 + detail.gst_percentage)) * 100;
      detail['buyer_total_gst_price'] = (this.updatedTotalPrice(detail.dispatch_quantity, detail.buyer_unit_price) * detail.gst_percentage) / (100 + detail.gst_percentage);
    },

    updatedTotalPrice(qty, price) {
      return qty * price;
    },

    calculateVendorTotalPrice(details) {
      let totalPrice = null;
      for (let detail of details.bid_details) {
        totalPrice += this.updatedTotalPrice(detail.dispatch_quantity, detail.price);
      }
      return totalPrice +  details.loading + details.unloading + details.freight + details.freight_gst_price + details.loading_gst_price + details.unloading_gst_price;
    },

    calculateBuyerTotalPrice(details) {
      let totalPrice = null;
      for (let detail of details.bid_details) {
        totalPrice += this.updatedTotalPrice(detail.dispatch_quantity, detail.buyer_unit_price);
      }
      return this.formatPrice(totalPrice + details.loading + details.unloading + details.freight + details.freight_gst_price + details.loading_gst_price + details.unloading_gst_price);
    },

    calculateQuantity(details) {
      let totalQty = null;
      let bagPrice = null;
      for (let detail of details.bid_details) {
        if (detail.unit_name == "Bag") {
          bagPrice = (detail.dispatch_quantity * 50) / 1000;
          totalQty += bagPrice;
        } else {
          totalQty += detail.dispatch_quantity;
        }
      }
      return totalQty;
    },
    async onVendorPriceChange(detail) {
      if (this.bidDetails.category.id !== 2 || detail.attribute_option_name !== '20 mm') {
        return;
      }
      try {
        const res = await getVendorTMTBarPrice({amount: detail.price});
        this.bidDetails.bid_details
          .filter(bid => bid.attribute_option_name !== '20 mm')
          .forEach(bid => {
            const selectedAttribute = res.find(attr => attr.attribute_option_name === bid.attribute_option_name);
            if (selectedAttribute) {
              bid.price = selectedAttribute.amount + detail.price;
              this.calculateVendorGSTPrice(bid);
            }
          });
      } catch (error) {
        console.error('Error updating TMT bar prices:', error);
      }
    },

    getBids() {
      this.isLoading = true;
      try {
        getBidDetail(
          {
            limit: 25,
            include: "bidDetail,brand,user,bidStatus,bidDetail.bid.vendorOrder.order.orderProducts.product,vendorOrder.order,category,vendorOrder.order,bidDetail.product,vendorOrder.order.paymentTerms",
          },
          this.bidId
        )
          .then((res) => {
            this.bidDetails = res;
            this.afterVendorPriceChange(null,this.bidDetails.total);
            this.getGSTPercentageGradeWise();
            this.updatedPaymentTerm = res.payment_term_days == 0 || res.payment_term_days == null ? "Advance" : `${res.payment_term_days} day(s)`;
            this.paymentTermDays = res.payment_term_days == null ? 0 : res.payment_term_days;
            this.longitude = this.bidDetails.vendorOrder.project.longitude;
            this.latitude = this.bidDetails.vendorOrder.project.latitude;
            this.google_map_link = this.bidDetails.vendorOrder.project.google_map_link;
            this.vendorOrderId = res.vendor_order_id
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } catch {
        this.isLoading = false;
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      }
    },

    redirectToGoogleMaps(data) {
      window.open(`http://maps.google.com?q=${data.latitude},${data.longitude}`, "_blank");
    },
    toggleBuyerPriceDisability(detail) {
      if (detail.isBuyerPriceDisabled === undefined) {
        this.$set(detail, "isBuyerPriceDisabled", false);
      } else {
        detail.isBuyerPriceDisabled = !detail.isBuyerPriceDisabled;
      }
    },

    async submitPrice(fromAssign = false, close, reason) {
      let postData = {
        bid_details: [],
        assign_reason: reason
      };
      this.bidDetails.bid_details.map((bid) => {
        postData.bid_details.push({
          id: bid.id,
          price: bid.price,
          dispatch_quantity: bid.dispatch_quantity,
          buyer_unit_price: bid.buyer_unit_price,
        });
      });
      updateMultipleBidPrice(postData)
        .then(() => {
          if (fromAssign) {
            this.assignBid(close);
          }
          this.getBids();
          if (!fromAssign) {
            this.$shilp.notify({
              title: "Price updated successfully",
              type: "success",
            });
            this.$router.push({ name: "vendor-bids-list", params: { id: this.$route.params.id, order_no: "" } });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$shilp.notify({
            title: err.data.message,
            type: "danger",
          });
        });
    },

    saveAndAssign(inValid, from) {
      if (!inValid && from == "save") {
        this.submitPrice(false);
      } else if (!inValid && from == "assign") {
        const isRequired = this.bidDetails.bid_details.some((res) => {
          return res.price > 0 && res.buyer_unit_price > 0;
        });
        const isValid = this.validateBids(this.bidDetails.bid_details);
        const isMarginValid = this.comparePriceMargin(this.bidDetails.bid_details);
        if (isRequired && isValid || isRequired && this.hasAllPermissions) {
          if (isValid && !isMarginValid) {
            const msgPrice = `Are you sure you want to assign bid to ${this.bidDetails.user.name}?`;
            this.$shilp.confirm({
              title: "Assign Confirmation",
              message: msgPrice,
              ok: ({ close }) => {
                this.submitPrice(true, close);
              },
            });
          } else {
            this.$root.$emit("shilp-modal-open", "modal-assign-confirmation");
          }

        } else if (!isValid) {
          this.$shilp.notify({
            title: "The buyer's price must be equal to or higher than the bid price.",
            type: "danger",
          });
        }
        else {
          this.$shilp.notify({
            title: "Please enter the bid price and the buyer's price.",
            type: "danger",
          });
        }

      } else {
        this.$shilp.notify({
          title: "Please enter bid price and buyer price.",
          type: "danger",
        });
      }
    },

    validateBids(bids) {
      for (let i = 0; i < bids.length; i++) {
        const bid = bids[i];
        if (bid.price <= 0) {
          return false;
        }
        if (bid.buyer_unit_price <= bid.price) {
          return false;
        }
      }
      return true;
    },

    comparePriceMargin(bids) {
      let hasExcessMargin = false;
      for (let bid of bids) {
        const increasedPercentage = ((bid.buyer_unit_price - bid.price) / bid.buyer_unit_price) * 100;
        const excessPercentage = increasedPercentage - bid.product.margin_percentage;
         bid['marginCount'] = increasedPercentage;
        if (excessPercentage > 0) {
          hasExcessMargin = true;
        }
      }
      return hasExcessMargin;
    },


    assignOrder(event) {
      this.submitPrice(true, null, event);
      this.$root.$emit("shilp-modal-close", "modal-assign-confirmation");
    },

    assignBid(close) {
      try {
        const postData = {
          bid_status_id: 4,
        };
        updateBidAssign(this.bidDetails.id, postData)
          .then(() => {
            this.$emit("close");
            this.$root.$emit("shilp-modal-close", "bid-assign-modal");
            this.$shilp.notify({
              title: "Bid Assigned successfully",
              type: "success",
            });
            this.$router.push({ name: "vendor-assigned-order-list" });
            close();
          })
          .catch((err) => {
            console.log(err);
            this.$shilp.notify({
              title: err.data.message,
              type: "danger",
            });
          });
      } catch {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      }
    },

    updateBid() {
      const postData = {
        payment_term_days: this.paymentTermDays,
      };
      updateBidDetail(this.bidId, postData).then(() => {
        this.getBids();
      });
    },

    generateMapLink() {
      if (this.latitude && this.longitude) {
        this.google_map_link = `https://maps.google.com/?q=${this.latitude},${this.longitude}`;
      }
    },
    updateProjectMapLocation() {
      const postData = {
        latitude: this.latitude,
        longitude: this.longitude,
        google_map_link: this.google_map_link
      };
      const projectId = this.bidDetails.vendorOrder.project.id;
      updateGeoLocation(projectId, postData).then(() => {
        this.$refs.popover.hide();
        this.getBids();
        this.$shilp.notify({
          title: "Location updated successfully.",
          type: "success",
        });
      }).catch(() => {
        this.$shilp.notify({
          title: "Something went wrong. Try Again.",
          type: "danger",
        });
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.price-label {
  @apply text-sm font-medium text-gray-600;
}

.price-value {
  @apply font-medium text-xl text-gray-900;
}

.order-total {
  td {
    @apply pl-2;
  }
  @apply border-none;
}

.note-grid {
  grid-column-start: 4;
  grid-column-end: 7;
}

::v-deep .status-label {
  @apply text-sm;
}
</style>